import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'
import {Redirect, Route, Switch} from 'react-router'

import ApplicationsList from './admin/applications/List'
import NotificationTemplatesList from './admin/notification-templates/List'
import UsersList from './admin/users/List'
import routes from '../utils/routes'

import ReportRevenues from './admin/reports/revenues/List'
import ReportSubscriptions from './admin/reports/subscriptions/List'
import ReportFailedPayments from './admin/reports/failed-payments/List'

class LoggedAdminRoot extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path={routes.adminApplications} component={ApplicationsList}/>
                <Route exact path={routes.adminNotificationTemplates} component={NotificationTemplatesList}/>
                <Route exact path={routes.adminUsers} component={UsersList}/>

                <Route exact path={routes.adminReportRevenues} component={ReportRevenues}/>
                <Route exact path={routes.adminReportSubscriptions} component={ReportSubscriptions}/>
                <Route exact path={routes.adminReportFailedPayments} component={ReportFailedPayments}/>

                <Redirect to={routes.adminApplications}/>
            </Switch>
        )
    }
}

LoggedAdminRoot.propTypes = {
    account: PropTypes.instanceOf(immutable.Map).isRequired,
}

export default LoggedAdminRoot
