import {getNotifications} from '../apis/notifications'
import {updateNotificationApi} from '../apis/notifications'


const NotificationsActions = {
    REQUEST_NOTIFICATIONS: 'API_NOTIFICATIONS_LISTS_REQUEST',
    RECEIVE_NOTIFICATIONS: 'API_NOTIFICATIONS_LISTS_RECEIVE',
    UPDATE_NOTIFICATION: 'API_NOTIFICATION_UPDATE',
}

const requestNotifications = () => ({
    type: NotificationsActions.REQUEST_NOTIFICATIONS,
})

const receiveNotifications = (notifications) => ({
    type: NotificationsActions.RECEIVE_NOTIFICATIONS,
    notifications
})

const updateNotificationAction = (notificationId) => {
    return {
        type: NotificationsActions.UPDATE_NOTIFICATION,
        notificationId
    }
}

const updateNotification = ({notificationId}) => (dispatch) => {
    dispatch(updateNotificationAction(notificationId))
    return updateNotificationApi(notificationId)
}

const fetchNotifications = (userId) => (dispatch) => {
    dispatch(requestNotifications())
    return getNotifications(userId).then(notifications => dispatch(receiveNotifications(notifications)))
}

const shouldFetchNotifications = (state) => {
    const notifications = state.get('notifications')
    return !notifications || !notifications.get('isFetching')
}

const fetchNotificationsIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchNotifications(getState())) {
        return dispatch(fetchNotifications(getState().getIn(['account', 'id'])))
    }
}

export {
    NotificationsActions,
    fetchNotificationsIfNeeded,
    updateNotification,
}