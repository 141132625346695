import React from 'react'
import * as PropTypes from 'prop-types'
import * as immutable from 'immutable'
import {connect} from 'react-redux'
import {fetchUsersIfNeeded} from '../../../actions/admin/users'
import {updateUserMailPriority} from '../../../actions/admin/users'


class UserProfile extends React.Component {
    componentDidMount() {
        const {dispatch} = this.props
        dispatch(fetchUsersIfNeeded())
    }

    handleChange = () => {
        const {dispatch} = this.props
        dispatch(updateUserMailPriority())
    }

    render() {
        const {account, users, isFetchingUsers} = this.props

        if (isFetchingUsers) {
            return null
        }

        const user = users.find((aUser) => {
            return aUser.get('id') === account.get('id')
        })

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <label><strong className="mr-1">Name:</strong>{user.get('name')}</label>
                    </div>
                    <div className="col-md-6">
                        <a href="#" className="small">change password</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label><strong className="mr-1">Primary email:</strong>{user.get('primaryEmail')}</label>
                    </div>
                    <div className="col-md-6">
                        <div className="form-check small">
                            <input
                                type="checkbox"
                                id="primaryEmail"
                                checked={user.get('primaryEmailNotifications')}
                                onChange={this.handleChange}
                                className="form-control form-check-input"/>
                            <label className="form-check-label mr-5" htmlFor="primaryEmail">Set for notifications</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label><strong className="mr-1">Secondary email:</strong>{user.get('secondaryEmail')}</label>
                    </div>
                    <div className="col-md-6">
                        <div className="form-check small">
                            <input
                                type="checkbox"
                                id="secondaryEmail"
                                checked={!user.get('primaryEmailNotifications')}
                                onChange={this.handleChange}
                                className="form-control form-check-input"/>
                            <label className="form-check-label mr-5" htmlFor="secondaryEmail">Set for notifications</label>
                        </div>
                    </div>
                </div>
                <hr/>
                <h4 className="mb-3">Company Details</h4>
                <div className="d-flex flex-column">
                    <label><strong className="mr-1">Company:</strong>{user.get('company')}</label>
                    <label><strong className="mr-1">Address:</strong>{user.get('address')}</label>
                    <label><strong className="mr-1">Phone:</strong>{user.get('phone')}</label>
                    <label><strong className="mr-1">Town:</strong>{user.get('town')}</label>
                    <label><strong className="mr-1">County:</strong>{user.get('county')}</label>
                    <label><strong className="mr-1">Postal Code:</strong>{user.get('postalCode')}</label>
                    <label><strong className="mr-1">Country:</strong>{user.get('country')}</label>
                </div>
            </React.Fragment>
        )
    }
}


UserProfile.propTypes = {
    users: PropTypes.instanceOf(immutable.List).isRequired,
    account: PropTypes.instanceOf(immutable.Map).isRequired,
    isFetchingUsers: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const users = state.getIn(['admin', 'users']) || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        account: state.get('account'),
        users: users.get('list'),
        isFetchingUsers: users.get('isFetching'),
    }
}

export default connect(mapStateToProps)(UserProfile)
