import * as immutable from 'immutable'

import config from '../../config/config'
import {get, put} from './handler'


const getNotifications = (userId) => {
    return get(`${config.apiUrl}/notifications/${userId}`).then((response) => {
        return immutable.fromJS(response.data)
    }).catch(() => {return immutable.List()})
}

const updateNotificationApi = (notificationId) => {
    return put(`${config.apiUrl}/notifications/${notificationId}`)
}

export {
    getNotifications,
    updateNotificationApi
}
