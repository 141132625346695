import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'
import moment from 'moment'

import Logo from './Logo'


class Row extends React.Component {
    render() {
        const {application, onEditClick, onDeleteClick} = this.props

        return (
            <tr>
                <td className='align-middle'>{application.get('name')}</td>
                <td className='align-middle'>{application.get('categories')}</td>
                <td className='align-middle'>{moment(application.get('modified')).format('Do MMM YYYY HH:mm')}</td>
                <td className='align-middle text-center w-25'>
                    <Logo
                        onClick={onEditClick}
                        imageUrl={application.get('imageUrl')}
                        className='img-thumbnail hoverable pointer'
                        noImageClass='pointer'/>
                </td>
                <td className='align-middle text-center'>
                    <i
                        className="fas fa-pencil-alt pointer mx-2"
                        title="Edit Application"
                        onClick={onEditClick}/>
                    <i
                        className="far fa-trash-alt pointer mx-2"
                        title="Delete Application"
                        onClick={() => onDeleteClick(application.get('id'))}/>
                </td>
            </tr>
        )
    }
}


Row.propTypes = {
    application: PropTypes.instanceOf(immutable.Map).isRequired,
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
}

export default Row
