import {getApplications} from '../../apis/admin/applications'


const ApplicationsActions = {
    REQUEST_APPLICATIONS: 'API_ADMIN_APPLICATIONS_LISTS_REQUEST',
    RECEIVE_APPLICATIONS: 'API_ADMIN_APPLICATIONS_LISTS_RECEIVE',
    UPDATE_APPLICATION: 'API_ADMIN_APPLICATION_UPDATE',
    ADD_APPLICATION: 'API_ADMIN_APPLICATION_ADD',
    REMOVE_APPLICATION: 'API_ADMIN_APPLICATION_REMOVE',
}

const requestApplications = () => ({
    type: ApplicationsActions.REQUEST_APPLICATIONS,
})

const receiveApplications = (applications) => ({
    type: ApplicationsActions.RECEIVE_APPLICATIONS,
    applications
})

const updateApplication = (application) => ({
    type: ApplicationsActions.UPDATE_APPLICATION,
    application
})

const addApplication = (application) => ({
    type: ApplicationsActions.ADD_APPLICATION,
    application
})

const removeApplication = (applicationId) => ({
    type: ApplicationsActions.REMOVE_APPLICATION,
    applicationId
})

const fetchApplications = () => (dispatch) => {
    dispatch(requestApplications())
    return getApplications().then(applications => dispatch(receiveApplications(applications)))
}

const shouldFetchApplications = (state) => {
    const applications = state.getIn(['admin', 'applications'])
    return !applications || !applications.get('isFetching')
}

const fetchApplicationsIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchApplications(getState())) {
        return dispatch(fetchApplications())
    }
}

export {
    ApplicationsActions,
    fetchApplicationsIfNeeded,
    updateApplication,
    addApplication,
    removeApplication,
}
