import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'
import {Link} from 'react-router-dom'
import classNames from 'classnames'

import Logo from '../admin/applications/Logo'


const ApplicationLink = ({application}) => {
    return (
        <a href={application.get('url')}>
            <div className={classNames('card mr-3 mb-3 hoverable')} style={{ height: "280px", width: "350px" }}>
                <div className="card-body p-3 text-center" style={{ width: "350px" }}>
                    <Logo
                        style={{ height: "313px", width: "500px" }}
                        imageUrl={application.get('imageUrl')}
                        title={application.get('name')}
                        className='img-fluid pointer logo-no-image-width'
                        noImageClass='pointer pt-5 logo-no-image-width logo-no-image-height'/>
                </div>
                <div className="card-header bg-transparent text-center black-ic font-weight-bold">
                    {application.get('name')}
                </div>
            </div>
        </a>
    )
}


ApplicationLink.propTypes = {
    application: PropTypes.instanceOf(immutable.Map).isRequired,
}

export default ApplicationLink
