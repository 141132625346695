import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'
import classNames from 'classnames'
import {connect} from 'react-redux'
import {updateNotification} from '../../../actions/notifications'


class ActionsRow extends React.Component {
    state = {
        isSending: false
    }

    onNotificationClick = () => {
        const {notification, dispatch} = this.props
        this.setState(
            {isSending: true},
            () => {
                dispatch(updateNotification({notificationId: notification.get('id')}))
                    .then(() => {
                        this.setState({isSending: false})
                    })
            }
        )
    }

    render() {
        const {notification} = this.props
        const {isSending} = this.state

        return (
            <div className="text-center">
                <span onClick={this.onNotificationClick} className={classNames({'spinner-border spinner-border-sm': isSending})} role="status">
                    <span className={classNames({'sr-only': isSending})}>
                        {!notification.get('read') &&
                        <i
                            className="fas fa-envelope pointer mx-1"
                            title="Mark as read"/>
                        }
                        {notification.get('read') &&
                        <i
                            className="fas fa-envelope-open pointer mx-1"
                            title="Mark as unread"/>
                        }
                    </span>
                </span>
            </div>
        )
    }
}


ActionsRow.propTypes = {
    notification: PropTypes.instanceOf(immutable.Map).isRequired,
    dispatch: PropTypes.func.isRequired,
}

export default connect()(ActionsRow)
