import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import * as immutable from 'immutable'
import {Link} from 'react-router-dom'

import routes from '../../utils/routes'
import {buildUrl, myAccountTabs} from '../../utils/routes'


const tabsText = Object.freeze({
    [myAccountTabs.userProfile]: 'User Profile',
    [myAccountTabs.subscriptions]: 'Subscriptions',
    [myAccountTabs.accountDetails]: 'Account Details',
    [myAccountTabs.notifications]: 'Notifications',
})

class TabsNavigation extends React.Component {
    getTabsHeaders = () => {
        let {currentTab} = this.props
        let tabsKeys = []
        for (const [key, value] of Object.entries(myAccountTabs)) {
            tabsKeys.push(value)
        }
        tabsKeys = immutable.List(tabsKeys)
        return tabsKeys.map((tab, index) => (
            <li className="nav-item flex-grow-1" key={index}>
                <Link
                    to={buildUrl(routes.myAccountTabs, {tab})}
                    className={classNames('nav-link rounded light-grey-ic', {active: currentTab === tab})}>
                    {tabsText[tab]}
                </Link>
            </li>
        ))
    }

    render() {
        return (
            <ul className="nav nav-tabs text-center">
                {this.getTabsHeaders()}
            </ul>
        )
    }
}

TabsNavigation.propTypes = {
    currentTab: PropTypes.string.isRequired,
}

export default TabsNavigation
