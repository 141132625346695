import * as PropTypes from 'prop-types'
import React from 'react'
import {Dropdown, DropdownToggle, DropdownMenu, MDBDropdownItem} from 'mdbreact'
import ReactTimeAgo from 'react-time-ago'
import * as immutable from 'immutable'
import {Link} from 'react-router-dom'
import moment from 'moment'

import routes from '../../utils/routes'


class NotificationsBell extends React.Component {
    renderNotifications = () => {
        const {notifications} = this.props
        return notifications
            .sort((notA, notB) => {
                const notAM = moment.utc(notA.get('created'))
                const notBM = moment.utc(notB.get('created'))
                if (notAM < notBM) { return -1 }
                if (notAM > notBM) { return 1 }
                if (notAM === notBM) { return 0 }
            })
            .reverse()
            .map((notification, key) => {
                return (
                    <React.Fragment key={key} >
                        <MDBDropdownItem className="">
                            <Link to={routes.notifications} className="p-0">
                                <div className="row no-gutters m-1">
                                    <div className="flex-center h-auto">
                                        <span className="fa-stack light-grey-ic">
                                            <i className="fa fa-circle fa-stack-2x"/>
                                            <i className="far fa-bell fa-stack-1x fa-inverse"/>
                                        </span>
                                    </div>
                                    <div className="col-10 ml-2">
                                        <div className="row no-gutters p-0">
                                            <div className="col-12">
                                                <span className="text-truncate d-block">
                                                    {`${notification.get('details')}`}
                                                </span>
                                            </div>
                                            <div className="col-12">
                                                <ReactTimeAgo
                                                    className="light-grey-ic"
                                                    tooltip={false}
                                                    date={moment.utc(notification.get('created')).toDate()}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </MDBDropdownItem>
                        <hr className="m-0"/>
                    </React.Fragment>
                )
            })
    }

    render() {
        const {notifications} = this.props
        return (
            <Dropdown>
                <DropdownToggle nav>
                    <span className="fa-stack notifications" data-count={notifications.size > 0 ? notifications.size : null}>
                        <i className="fa fa-bell fa-stack-1x"/>
                    </span>
                </DropdownToggle>
                <DropdownMenu right className="p-0 btn-expand w-50">
                    {this.renderNotifications()}
                    <MDBDropdownItem className="text-center">
                        <Link to={routes.notifications} className="light-blue-ic small font-weight-bold">
                            See All Notifications
                        </Link>
                    </MDBDropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }
}


NotificationsBell.propTypes = {
    notifications: PropTypes.instanceOf(immutable.List).isRequired
}

export default NotificationsBell