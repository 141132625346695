import * as immutable from 'immutable'

import config from '../../../config/config'
import {get, put} from '../handler'


const getUserApps = (userId) => {
    return get(`${config.apiUrl}/user-applications/${userId}`).then((response) => {
        return immutable.fromJS(response.data)
    }).catch(() => {
        return immutable.List()
    })
}

const updateUserApps = ({userId, applicationId, enabled}) => {
    return put(`${config.apiUrl}/user-applications/${userId}/${applicationId}`, {
        enabled
    }).catch((response) => {
        return response
    })
}

export {
    getUserApps,
    updateUserApps,
}
