import * as immutable from 'immutable'

import config from '../../config/config'
import {get} from './handler'


const getSubscriptions = (userId) => {
    return get(`${config.apiUrl}/subscriptions/${userId}`).then((response) => {
        return immutable.fromJS(response.data)
    }).catch(() => {return immutable.List()})
}

export {
    getSubscriptions
}
