import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import * as immutable from 'immutable'
import {connect} from 'react-redux'

import List from './List'
import {fetchSubscriptionsIfNeeded} from '../../../actions/subscriptions'
import {fetchApplicationsIfNeeded} from '../../../actions/admin/applications'

const tabs = Object.freeze({
    Actives: 'ACTIVE_SUBSCRIPTIONS',
    Additional: 'ADDITIONAL_SUBSCRIPTIONS'
})

const tabsText = Object.freeze({
    [tabs.Actives]: 'Active Subscriptions',
    [tabs.Additional]: 'Add another OmniPro Subscription',
})


class Subscriptions extends React.Component {
    state = {
        activeTab: tabs.Actives,
        activeApplication: null,
    }

    componentDidMount = () => {
        const {dispatch} = this.props
        dispatch(fetchSubscriptionsIfNeeded())
        dispatch(fetchApplicationsIfNeeded())
    }

    onApplicationClick = (app) => {
        this.setState((prevState) => {
            if (prevState.activeApplication === app) {
                return {activeApplication: null}
            } else {
                return {activeApplication: app}
            }
        })
    }

    getTabsHeaders = (activeTab) => {
        let tabsKeys = []
        for (const [key, value] of Object.entries(tabs)) {
            tabsKeys.push(value)
        }
        tabsKeys = immutable.List(tabsKeys)
        return tabsKeys.map((tab, index) => (
            <li className="nav-item" key={index} onClick={() => this.onTabClick(tab)}>
                <span className={classNames('nav-link rounded pointer', {active: activeTab === tab})}>{tabsText[tab]}</span>
            </li>
        ))
    }

    onTabClick = (tabSelected) => this.setState({activeTab: tabSelected, activeApplication: null})

    render() {
        const {activeTab, activeApplication} = this.state
        const {subscriptions} = this.props

        const {isFetchingSubscriptions, isFetchingApplications} = this.props

        if (isFetchingSubscriptions || isFetchingApplications) {
            return null
        }

        return (
            <div>
                <ul className="nav nav-tabs text-center">
                    {this.getTabsHeaders(activeTab)}
                </ul>
                <List
                    subscriptions={subscriptions[activeTab]}
                    activeApplication={activeApplication}
                    onClick={this.onApplicationClick}/>
            </div>
        )
    }
}

Subscriptions.propTypes = {
    subscriptions: PropTypes.instanceOf(Object).isRequired,
    isFetchingSubscriptions: PropTypes.bool.isRequired,
    isFetchingApplications: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const subscriptions = state.get('subscriptions') || immutable.Map({isFetching: true, list: immutable.List()})
    const applications = state.getIn(['admin', 'applications']) || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        subscriptions: {
            [tabs.Actives]: subscriptions
                .get('list')
                .map(subscription => subscription.get('application')
                    .set('subscribed', true)
                    .set('subscription', subscription))
            ,
            [tabs.Additional]: applications
                .get('list')
                .filter(application =>
                    subscriptions.get('list')
                        .filter(subscription => subscription.getIn(['application', 'id']) === application.get('id')).size === 0)
                .map(application => application.set('subscribed', false))
            ,
        },
        isFetchingSubscriptions: subscriptions.get('isFetching'),
        isFetchingApplications: applications.get('isFetching')
    }
}

export default connect(mapStateToProps)(Subscriptions)
