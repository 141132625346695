import {combineReducers} from 'redux-immutable'

import admin from './admin/admin'
import account from './account'
import applications from './applications'
import notifications from './notifications'
import payments from './payments'
import subscriptions from './subscriptions'


export default combineReducers({
    account,
    admin,
    applications,
    notifications,
    payments,
    subscriptions,
})
