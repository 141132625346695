import * as axios from 'axios'
import authentication from 'react-azure-adb2c'
import jwt from 'jwt-decode'
import * as immutable from 'immutable'


const responseErrorOrGeneric = (error) => error.response && error.response.data && error.response.data.error ? error.response.data.error : 'There was an error processing your request'

const getToken = () => authentication.getAccessToken()

const getAccount = () => {
    const decoded = jwt(authentication.getAccessToken())
    return immutable.Map({
        id: decoded['oid'],
        name: decoded['given_name'] + ' ' + decoded['family_name'],
        email: decoded['emails'][0],
        isAdmin: decoded['extension_IsAdmin']
    })
}

const post = (url, params) => {
    const token = getToken()
    const headers = {}
    
    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }
    
    return axios.post(url, params, {headers}).catch((error) => {
        throw Error(responseErrorOrGeneric(error))
    })
}

const remove = (url) => {
    const token = getToken()
    const headers = {}

    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }

    return axios.delete(url, {headers}).catch((error) => {
        throw Error(responseErrorOrGeneric(error))
    })
}

const get = (url) => {
    const token = getToken()
    const headers = {}
    
    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }
    
    return axios.get(url, {headers}).catch((error) => {
        throw Error(responseErrorOrGeneric(error))
    })
}

const put = (url, params) => {
    const token = getToken()
    const headers = {}

    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }

    return axios.put(url, params, {headers}).catch((error) => {
        throw Error(responseErrorOrGeneric(error))
    })
}

export {
    getToken,
    getAccount,
    post,
    put,
    get,
    remove,
}