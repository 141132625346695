import * as immutable from 'immutable'

import config from '../../../config/config'
import {get, post, put, remove} from '../handler'


const getUsers = () => {
    return get(`${config.apiUrl}/users`).then((response) => {
        return immutable.fromJS(response.data)
    }).catch((response) => {
        return response
    })
}

const createUserApi = (user) => {
    return post(`${config.apiUrl}/users/`, user).then((response) => {
        return immutable.Map(response.data)
    }).catch((response) => {
        return response
    })
}

const updateUserApi = (user) => {
    return put(`${config.apiUrl}/users/${user.id}`, user).then((response) => {
        return immutable.Map(response.data)
    })
}

const deleteUserApi = (userId) => {
    return remove(`${config.apiUrl}/users/${userId}`).then((response) => {
        return response.data
    })
}

const updateUserMailPriorityApi = (userId) => {
    return put(`${config.apiUrl}/users/mail-priority/${userId}`).then((response) => {
        return response
    })
}

export {
    getUsers,
    updateUserMailPriorityApi,
    createUserApi,
    updateUserApi,
    deleteUserApi,
}
