import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, MDBFileInput, MDBInput} from 'mdbreact'
import * as PropTypes from 'prop-types'
import * as immutable from 'immutable'
import classNames from 'classnames'

import {updateApplicationApi} from '../../../apis/admin/applications'


class Edit extends React.Component {
    state = {
        id: this.props.application.get('id'),
        name: this.props.application.get('name'),
        description: this.props.application.get('description'),
        buyUrl: this.props.application.get('buyUrl'),
        categories: this.props.application.get('categories'),
        tags: this.props.application.get('tags'),
        replyUrl: this.props.application.get('replyUrl'),
        url: this.props.application.get('url'),
        loading: false,
        image: null
    }

    handleChange = () => {
        this.setState(
            {loading: true},
            () => {
                const {updateApplication} = this.props
                updateApplicationApi(this.state)
                    .then((response) => {
                        updateApplication(response)
                    })
            }
        )
    }

    render() {
        const {onClose} = this.props
        const {loading, name, description, buyUrl, categories, tags, replyUrl, url} = this.state

        return (
            <Modal isOpen toggle={onClose}>
                <ModalBody>
                    <h5 className="py-2">
                        Update Application
                    </h5>
                    <div className="row">
                        <div className="col">
                            <MDBInput
                                disabled={loading}
                                label='Name'
                                value={name}
                                getValue={(e) => {this.setState({name: e})}}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <MDBInput
                                disabled={loading}
                                label='Description'
                                value={description}
                                getValue={(e) => {this.setState({description: e})}}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <MDBInput
                                disabled={loading}
                                label='Buy URL'
                                value={buyUrl}
                                getValue={(e) => {this.setState({buyUrl: e})}}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <MDBInput
                                disabled={loading}
                                label='Products'
                                value ={categories}
                                getValue={(e) => {this.setState({categories: e})}}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <MDBInput
                                disabled={loading}
                                label='External Application Parameters'
                                value ={tags}
                                getValue={(e) => {this.setState({tags: e})}}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <MDBInput
                                disabled={loading}
                                label='Reply Url'
                                value ={replyUrl}
                                getValue={(e) => {this.setState({replyUrl: e})}}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <MDBInput
                                disabled={loading}
                                label='Url'
                                value ={url}
                                getValue={(e) => {this.setState({url: e})}}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <MDBFileInput
                                disabled={loading}
                                textFieldTitle='Upload a logo'
                                btnTitle='Upload'
                                accept="image/*"
                                getValue={(files) => {this.setState({image: files[0]})}}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" outline onClick={this.handleChange} disabled={loading}>
                        <div className={classNames({'spinner-border spinner-border-sm': loading})} role="status">
                            <span className={classNames({'sr-only': loading})}>Update</span>
                        </div>
                    </Button>
                    <Button color="primary" outline onClick={onClose} disabled={loading}>close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}


Edit.propTypes = {
    application: PropTypes.instanceOf(immutable.Map).isRequired,
    onClose: PropTypes.func.isRequired,
    updateApplication: PropTypes.func.isRequired
}

export default Edit
