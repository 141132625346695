import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'
import classNames from 'classnames'

import {NotificationType} from '../../../utils/enums'


class Row extends React.Component {
    state = {
        showDetails: false
    }

    render() {
        const {notificationTemplate, onDetailsClick} = this.props
        let emailBody = notificationTemplate.get('emailBody').length ? `${notificationTemplate.get('emailBody').substr(0, 75)}...` : ''
        let inAppBody = notificationTemplate.get('inAppBody').length ? `${notificationTemplate.get('inAppBody').substr(0, 75)}...` : ''
        let emailDelivery = notificationTemplate.get('emailDelivery')
        let inAppDelivery = notificationTemplate.get('inAppDelivery')

        return (
            <tr onClick={onDetailsClick}>
                <td className="align-middle">{NotificationType[notificationTemplate.get('type')]}</td>
                <td className="align-middle text-center">
                    <i className={classNames('fas', {'fa-check': emailDelivery, 'fa-times': !emailDelivery})}/>
                </td>
                <td>{emailBody}</td>
                <td className="align-middle text-center">
                    <i className={classNames('fas', {'fa-check': inAppDelivery, 'fa-times': !inAppDelivery})}/>
                </td>
                <td>{inAppBody}</td>
                <td className="align-middle text-center">
                    <i
                        className="fas fa-pencil-alt pointer mx-2"
                        title="Template Details"
                        onClick={onDetailsClick}/>
                </td>
            </tr>
        )
    }
}


Row.propTypes = {
    onDetailsClick: PropTypes.func.isRequired,
    notificationTemplate: PropTypes.instanceOf(immutable.Map).isRequired,
}

export default Row
