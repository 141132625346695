import * as immutable from 'immutable'

import config from '../../config/config'
import {post, get} from './handler'


const resetPassword = (email) => {
    return get(`${config.apiUrl}/accounts/reset-password/${email}`).then((response) => {
        if(response.data === "Ok") {
            return immutable.Map({success: true})
        }
        else {
            return immutable.Map({success: false, reason: response.data})
        }
    }).catch((response) => {
        return response
    })
}

const updatePassword = (userId, token, password) => {
    const params = new FormData()
    params.append('password', password)

    return post(`${config.apiUrl}/accounts/update-password/${userId}/${token}`, params).then((response) => {
        if(response.data === "Ok") {
            return immutable.Map({success: true})
        }
        else {
            return immutable.Map({success: false, reason: response.data})
        }
    }).catch((response) => {
        return response
    })
}

export {
    updatePassword,
    resetPassword
}
