import {getPayments} from '../apis/payments'


const PaymentsActions = {
    REQUEST_PAYMENTS: 'API_PAYMENTS_LISTS_REQUEST',
    RECEIVE_PAYMENTS: 'API_PAYMENTS_LISTS_RECEIVE',
}

const requestPayments = () => ({
    type: PaymentsActions.REQUEST_PAYMENTS,
})

const receivePayments = (payments) => ({
    type: PaymentsActions.RECEIVE_PAYMENTS,
    payments
})

const fetchPayments = (userId) => (dispatch) => {
    dispatch(requestPayments())
    return getPayments(userId).then(notifications => dispatch(receivePayments(notifications)))
}

const shouldFetchPayments = (state) => {
    const payments = state.get('payments')
    return !payments || !payments.get('isFetching')
}

const fetchPaymentsIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchPayments(getState())) {
        return dispatch(fetchPayments(getState().getIn(['account', 'id'])))
    }
}

export {
    PaymentsActions,
    fetchPaymentsIfNeeded,
}