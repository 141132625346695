import * as PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import * as immutable from 'immutable'

import ApplicationLink from './ApplicationLink'
import {fetchApplicationsIfNeeded} from '../../actions/applications'


class Applications extends React.Component {
    componentDidMount = () => {
        const {dispatch} = this.props
        dispatch(fetchApplicationsIfNeeded())
    }

    renderApplicationLinks = () => {
        const {applications} = this.props
        return applications.map((application, index) =>
            <ApplicationLink application={application} key={index}/>
        )
    }

    render() {
        const {isFetchingApplications} = this.props

        const {applications} = this.props

        if (isFetchingApplications) {
            return null
        }

        if (applications.size === 0) {
            return (
                <div className="alert alert-primary mt-3">
                    <h4 className="alert-heading">Applications</h4>
                    <hr/>
                    <p className="mb-0">
                        There are no applications enabled.
                    </p>
                </div>
            )
        }

        return (
            <div className="my-3">
                <h2 className="font-weight-normal mt-4">Your Applications</h2>
                <label className="mx-3 small">Select from the list of applications you have a subscription to below</label>
                <div className="row mt-4">
                    {this.renderApplicationLinks()}
                </div>
            </div>
        )
    }
}


Applications.propTypes = {
    applications: PropTypes.instanceOf(immutable.List).isRequired,
    isFetchingApplications: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const applications = state.get('applications')
        || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        applications: applications.get('list'),
        isFetchingApplications: applications.get('isFetching')
    }
}

export default connect(mapStateToProps)(Applications)
