import React from 'react'
import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import {CSVLink} from 'react-csv'


class ExportCSVIcon extends React.Component {
    state = {
        isHovered: false,
    }

    handleHover = () => {
        this.setState(prevState => ({
            isHovered: !prevState.isHovered
        }))
    }

    render() {
        const {title, data, filename} = this.props

        if (!data) {
            return null
        }

        return (
            <CSVLink data={data} filename={filename}>
                <i
                    data-html2canvas-ignore="true"
                    title={title}
                    onMouseEnter={this.handleHover}
                    onMouseLeave={this.handleHover}
                    className={classNames(
                        'ml-3 pointer fas fa-file-csv',
                        {'black-ic': this.state.isHovered},
                        {'grey-ic': !this.state.isHovered}
                    )}/>
            </CSVLink>
        )
    }
}


ExportCSVIcon.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    filename: PropTypes.string,
}

export default ExportCSVIcon
