import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'


const Logo = ({imageUrl, className, noImageClass, title, onClick}) => {
    if (imageUrl) {
        return (
            <img
                className={className}
                title={title}
                src={imageUrl}
                onClick={onClick}
                alt="logo"/>
        )
    }
    return (
        <i
            onClick={onClick}
            title={title}
            className={classNames('far fa-times-circle fa-2x', noImageClass)}/>
    )
}


Logo.propTypes = {
    onClick: PropTypes.func,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    noImageClass: PropTypes.string,
}

export default Logo