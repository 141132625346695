import React from 'react'
import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import * as jsPDF from 'jspdf'
import * as html2canvas from 'html2canvas'


class ExportPDFIcon extends React.Component {
    state = {
        isHovered: false,
        isProcessing: false,
    }

    handleHover = () => {
        this.setState(prevState => ({
            isHovered: !prevState.isHovered
        }))
    }

    handlePrint = () => {
        this.setState(
            {isProcessing: true},
            () => {
                const input = document.getElementsByClassName('container')[0]
                const config = {logging: false, scale: 0.9}
                html2canvas(input, config)
                    .then((canvas) => {
                        const imgData = canvas.toDataURL('image/png')
                        const pdf = new jsPDF('landscape')
                        pdf.addImage(imgData, 'PNG', 10, 10)
                        pdf.save(this.props.filename)
                        this.setState({isProcessing: false})
                    })
            }
        )
    }

    render() {
        const {title} = this.props
        const {isProcessing} = this.state

        return (
            <span
                data-html2canvas-ignore="true"
                className={classNames({'spinner-border spinner-border-sm align-baseline ml-3': isProcessing})}>
                <span className={classNames({'sr-only': isProcessing})}>
                    <i
                        title={title}
                        onClick={this.handlePrint}
                        onMouseEnter={this.handleHover}
                        onMouseLeave={this.handleHover}
                        className={classNames(
                            'ml-3 pointer fas fa-file-pdf',
                            {'black-ic': this.state.isHovered},
                            {'grey-ic': !this.state.isHovered}
                        )}/>
                </span>
            </span>
        )
    }
}


ExportPDFIcon.propTypes = {
    title: PropTypes.string.isRequired,
    filename: PropTypes.string,
}

export default ExportPDFIcon
