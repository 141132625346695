import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, MDBInput} from 'mdbreact'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'

import {createUserApi} from '../../../apis/admin/users'


class Create extends React.Component {
    state = {
        loading: false,
        primaryEmailNotifications: true,
        secondaryEmailNotifications: false,
        isAdmin: false,
    }

    handleChange = () => {
        this.setState(
            {loading: true},
            () => {
                const {createUser} = this.props
                createUserApi(this.state)
                    .then((response) => {
                        createUser(response)
                    })
            }
        )
    }

    render() {
        const {onClose} = this.props
        const {
            loading, name, primaryEmail, primaryEmailNotifications, secondaryEmail, secondaryEmailNotifications,
            company, address, town, county, postalCode, country, phone, isAdmin
        } = this.state

        return (
            <Modal isOpen toggle={onClose}>
                <ModalBody>
                    <h3 className="pt-3 pr-2 border-bottom">
                        Create User
                    </h3>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                value={name}
                                label='Name'
                                disabled={loading}
                                onChange={(e) => {
                                    this.setState({name: e.target.value})
                                }}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                checked={isAdmin}
                                labelClass='w-100'
                                label="Is Admin?"
                                type="checkbox"
                                id="isAdmin"
                                onChange={(e) => {
                                    this.setState({isAdmin: e.target.checked})
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                value={primaryEmail}
                                type='email'
                                label='Primary Email'
                                onChange={(e) => {
                                    this.setState({primaryEmail: e.target.value})
                                }}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                checked={primaryEmailNotifications}
                                labelClass='w-100'
                                label="Receive notifications"
                                type="checkbox"
                                id="userPrimaryEmailForNotifications"
                                onChange={(e) => {
                                    this.setState({
                                        primaryEmailNotifications: e.target.checked,
                                        secondaryEmailNotifications: !e.target.checked
                                    })
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                value={secondaryEmail == null ? '' : secondaryEmail}
                                type='email'
                                label='Secondary Email'
                                onChange={(e) => {
                                    this.setState({secondaryEmail: e.target.value})
                                }}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                checked={secondaryEmailNotifications}
                                labelClass='w-100'
                                label="Receive notifications"
                                type="checkbox"
                                id="userSecondaryEmailForNotifications"
                                onChange={(e) => {
                                    this.setState({
                                        secondaryEmailNotifications: e.target.checked,
                                        primaryEmailNotifications: !e.target.checked
                                    })
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                value={company}
                                label='Company'
                                onChange={(e) => {
                                    this.setState({company: e.target.value})
                                }}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                value={address}
                                label='Address'
                                onChange={(e) => {
                                    this.setState({address: e.target.value})
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                value={town}
                                label='Town'
                                onChange={(e) => {
                                    this.setState({town: e.target.value})
                                }}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                value={county}
                                label='County'
                                onChange={(e) => {
                                    this.setState({county: e.target.value})
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                value={postalCode}
                                label='Postal Code'
                                onChange={(e) => {
                                    this.setState({postalCode: e.target.value})
                                }}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                disabled={loading}
                                value={country}
                                label='Country'
                                onChange={(e) => {
                                    this.setState({country: e.target.value})
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <MDBInput
                                disabled={loading}
                                value={phone}
                                label='Phone'
                                onChange={(e) => {
                                    this.setState({phone: e.target.value})
                                }}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" outline onClick={this.handleChange} disabled={loading}>
                        <div className={classNames({'spinner-border spinner-border-sm': loading})} role="status">
                            <span className={classNames({'sr-only': loading})}>Create</span>
                        </div>
                    </Button>
                    <Button color="primary" outline onClick={onClose} disabled={loading}>close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}


Create.propTypes = {
    onClose: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired
}

export default Create
