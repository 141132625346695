import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import * as immutable from 'immutable'
import Logo from '../../admin/applications/Logo'


const NavItem = ({application, isActive, onClick}) => {
    return (
        <div className="card my-3 pointer">
            <div onClick={() => onClick(application)} className={classNames('card-header text-center font-weight-bold white-ic',
                {'bg-header': isActive},
                {'bg-primary': !isActive})}>
                {application.get('name')}
            </div>
            {
                application.get('imageUrl') &&
                <div onClick={() => onClick(application)} className="card-body p-3 border border-info text-center">
                    <Logo
                        imageUrl={application.get('imageUrl')}
                        className='img-fluid'
                        noImageClass='pointer'/>
                </div>
            }
        </div>
    )
}


NavItem.propTypes = {
    application: PropTypes.instanceOf(immutable.Map).isRequired,
    isActive: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
}

export default NavItem
