import {combineReducers} from 'redux-immutable'

import applications from './applications'
import payments from './payments'
import subscriptions from './subscriptions'
import notificationTemplates from './notificationTemplates'
import users from './users'
import userApps from './userApplications'


export default combineReducers({
    applications,
    payments,
    subscriptions,
    notificationTemplates,
    users,
    userApps
})
