import * as immutable from 'immutable'

import {ApplicationsActions} from '../../actions/admin/applications'


export default (state = null, action) => {
    switch (action.type) {
        case ApplicationsActions.REQUEST_APPLICATIONS:
            state = immutable.Map({
                isFetching: true,
                list: immutable.List()})
            break
        case ApplicationsActions.RECEIVE_APPLICATIONS:
            state = immutable.Map({
                isFetching: false,
                list: action.applications
            })
            break
        case ApplicationsActions.UPDATE_APPLICATION:
            state = immutable.Map({
                isFetching: state.get('isFetching'),
                list: state.get('list').map((application) => {
                    if (application.get('id') === action.application.get('id')) {
                        return action.application
                    }
                    return application
                })
            })
            break
        case ApplicationsActions.REMOVE_APPLICATION:
            state = immutable.Map({
                isFetching: state.get('isFetching'),
                list: state.get('list').filter((application) => {
                    return application.get('id') !== action.applicationId
                })
            })
            break
        case ApplicationsActions.ADD_APPLICATION:
            state = immutable.Map({
                isFetching: state.get('isFetching'),
                list: state.get('list').push(action.application)
            })
            break
        default:
            break
    }
    return state
}
