import * as PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'

import LoggedAdminRoot from './LoggedAdminRoot'
import LoggedUserRoot from './LoggedUserRoot'
import Header from './header/Header'
import {getAccount} from '../apis/handler'
import {setAccount} from '../actions/account'

class LoggedRoot extends React.Component {
    state = {
        account: getAccount()
    }

    componentDidMount = () => {
        const {dispatch} = this.props
        const {account} = this.state
        dispatch(setAccount(account))
    }

    render() {
        return (
            <React.Fragment>
                <Header account={this.state.account}/>
                <main className="container">
                    <div>
                        {this.state.account.get('isAdmin') ? (
                            <LoggedAdminRoot account={this.state.account}/>
                        ) : (
                            <LoggedUserRoot account={this.state.account}/>
                        )}
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

LoggedRoot.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

export default connect(null)(LoggedRoot)