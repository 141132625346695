import {getNotificationTemplates, updateNotificationTemplateDelivery} from '../../apis/admin/notificationTemplates'


const NotificationTemplatesActions = {
    REQUEST_NOTIFICATION_TEMPLATES: 'API_ADMIN_NOTIFICATION_TEMPLATES_LISTS_REQUEST',
    RECEIVE_NOTIFICATION_TEMPLATES: 'API_ADMIN_NOTIFICATION_TEMPLATES_LISTS_RECEIVE',
    UPDATE_DELIVERY_NOTIFICATION_TEMPLATES: 'API_ADMIN_NOTIFICATION_TEMPLATES_UPDATE_DELIVERY',
}

const requestNotificationTemplates = () => ({
    type: NotificationTemplatesActions.REQUEST_NOTIFICATION_TEMPLATES,
})

const receiveNotificationTemplates = (notificationTemplates) => ({
    type: NotificationTemplatesActions.RECEIVE_NOTIFICATION_TEMPLATES,
    notificationTemplates
})

const updateDeliveryNotificationTemplate = ({id, inAppDelivery, emailDelivery}) => {
    return {
        type: NotificationTemplatesActions.UPDATE_DELIVERY_NOTIFICATION_TEMPLATES,
        id, inAppDelivery, emailDelivery
    }
}

const updateNotificationTemplate = (dispatch, state) => {
    updateNotificationTemplateDelivery(state)
    return dispatch(updateDeliveryNotificationTemplate(state))
}

const fetchNotificationTemplates = () => (dispatch) => {
    dispatch(requestNotificationTemplates())
    return getNotificationTemplates().then(notificationTemplates => dispatch(receiveNotificationTemplates(notificationTemplates)))
}

const shouldFetchNotificationTemplates = (state) => {
    const notificationTemplates = state.getIn(['admin', 'notificationTemplates'])
    return !notificationTemplates || !notificationTemplates.get('isFetching')
}

const fetchNotificationTemplatesIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchNotificationTemplates(getState())) {
        return dispatch(fetchNotificationTemplates())
    }
}

export {
    NotificationTemplatesActions,
    fetchNotificationTemplatesIfNeeded,
    updateNotificationTemplate,
}
