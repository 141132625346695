const AccountActions = {
    SET_ACCOUNT: 'SET_ACCOUNT',
}

const setAccount = (account) => ({
    type: AccountActions.SET_ACCOUNT,
    account
})

export {
    AccountActions,
    setAccount,
}
