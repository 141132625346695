import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'

import DetailsAdditional from './DetailsAdditional'
import NavList from './NavList'
import DetailsActive from './DetailsActive'


const List = ({subscriptions, activeApplication, onClick}) => {
    if (subscriptions.size === 0) {
        return (
            <div className="alert alert-primary mt-3">
                <h4 className="alert-heading">Subscriptions</h4>
                <hr/>
                <p className="mb-0">
                    There are no active subscriptions.
                </p>
            </div>
        )
    }

    return (
        <div className="row">
            <NavList activeApplication={activeApplication} applications={subscriptions} onClick={onClick}/>
            {
                activeApplication && activeApplication.get('subscribed') &&
                <DetailsActive application={activeApplication}/>
            }
            {
                activeApplication && !activeApplication.get('subscribed') &&
                <DetailsAdditional application={activeApplication}/>
            }
        </div>
    )
}

List.propTypes = {
    subscriptions: PropTypes.instanceOf(immutable.List).isRequired,
    activeApplication: PropTypes.instanceOf(immutable.Map),
    onClick: PropTypes.func.isRequired,
}

export default List
