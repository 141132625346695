import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'


const DetailsAdditional = ({application}) => {
    return (
        <div className="m-3 border border-info col-6">
            <dl className="dl-horizontal">
                <dt>Subscription Title</dt>
                <dd>{application.get('name')}</dd>
                <dt>Description</dt>
                <dd>{application.get('description')}</dd>
                <dt>Add subscription</dt>
                <dd><a target="_blank" href={application.get('buyUrl')}>{application.get('buyUrl')}</a></dd>
            </dl>
        </div>
    )
}


DetailsAdditional.propTypes = {
    application: PropTypes.instanceOf(immutable.Map).isRequired,
}

export default DetailsAdditional
