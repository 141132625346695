import * as immutable from 'immutable'

import {SubscriptionsActions} from '../../actions/admin/subscriptions'


export default (state = null, action) => {
    switch (action.type) {
        case SubscriptionsActions.REQUEST_SUBSCRIPTIONS:
            state = immutable.Map({
                isFetching: true,
                list: immutable.List()})
            break
        case SubscriptionsActions.RECEIVE_SUBSCRIPTIONS:
            state = immutable.Map({
                isFetching: false,
                list: action.subscriptions
            })
            break
        default:
            break
    }
    return state
}
