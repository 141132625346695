import * as PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import * as immutable from 'immutable'

import Row from './Row'
import Edit from './Edit'
import Create from './Create'
import Delete from './Delete'
import CreateIcon from '../../buttons/CreateIcon'
import {
    addApplication,
    fetchApplicationsIfNeeded,
    removeApplication,
    updateApplication
} from '../../../actions/admin/applications'


class List extends React.Component {
    state = {
        selectedApplication: null,
        createApplication: false,
        deleteApplication: null,
    }

    componentDidMount = () => {
        const {dispatch} = this.props
        dispatch(fetchApplicationsIfNeeded())
    }

    handleUpdate = () => {
        const {dispatch} = this.props
        const {selectedApplication} = this.state
        dispatch(updateApplication(selectedApplication))
        this.setState({selectedApplication: null})
    }

    handleCreate = (application) => {
        const {dispatch} = this.props
        dispatch(addApplication(application))
        this.setState({createApplication: false})
    }

    handleDelete = (applicationId) => {
        const {dispatch} = this.props
        dispatch(removeApplication(applicationId))
        this.setState({deleteApplication: null})
    }

    getApplications = () => {
        const {applications} = this.props
        return applications.map((application, index) =>
            <Row
                key={index}
                application={application}
                onEditClick={() => this.setState({selectedApplication: application})}
                onDeleteClick={() => this.setState({deleteApplication: application.get('id')})}/>
        )
    }

    render() {
        const {isFetchingApplications, applications} = this.props
        const {selectedApplication, createApplication, deleteApplication} = this.state

        if (isFetchingApplications) {
            return null
        }

        if (applications.size === 0) {
            return (
                <React.Fragment>
                    <div className="alert alert-primary mt-3">
                        <h4 className="alert-heading">Applications</h4>
                        <hr/>
                        <p className="mb-0">
                            There are no applications.
                            <a
                                className="mx-2"
                                onClick={() => this.setState({createApplication: true})}>Click here to create the first one.</a>
                        </p>
                    </div>
                    {
                        createApplication &&
                        <Create
                            onClose={() => this.setState({createApplication: false})}
                            createApplication={(application) => this.handleCreate(application)}/>
                    }
                </React.Fragment>
            )
        }

        return (
            <div>
                <h4 className="my-3">
                    Applications
                    <CreateIcon title='Create Application' onClick={() => this.setState({createApplication: true})}/>
                </h4>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Categories</th>
                            <th>Modified</th>
                            <th className="text-center">Image</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getApplications()}
                    </tbody>
                </table>
                {
                    selectedApplication &&
                    <Edit
                        application={selectedApplication}
                        onClose={() => this.setState({selectedApplication: null})}
                        updateApplication={(application) => this.setState(
                            {selectedApplication: application},
                            this.handleUpdate)}/>
                }
                {
                    createApplication &&
                    <Create
                        onClose={() => this.setState({createApplication: false})}
                        createApplication={(application) => this.handleCreate(application)}/>
                }
                {
                    deleteApplication &&
                    <Delete
                        applicationId={deleteApplication}
                        onClose={() => this.setState({deleteApplication: null})}
                        deleteApplication={(applicationId) => this.handleDelete(applicationId)}/>
                }
            </div>
        )
    }
}


List.propTypes = {
    applications: PropTypes.instanceOf(immutable.List).isRequired,
    isFetchingApplications: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const applications = state.getIn(['admin', 'applications'])
        || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        applications: applications.get('list')
            .sort((appA, appB) => {
                if (appA.get('name') < appB.get('name')) { return -1 }
                if (appA.get('name') > appB.get('name')) { return 1 }
                if (appA.get('name') === appB.get('name')) { return 0 }
            }),
        isFetchingApplications: applications.get('isFetching'),
    }
}

export default connect(mapStateToProps)(List)
