import * as PropTypes from 'prop-types'
import React from 'react'
import {InputSwitch} from 'mdbreact'
import {connect} from 'react-redux'

import {updateUserApplication} from '../../../actions/admin/userApplications'


class Toggle extends React.Component {
    state = {
        checked: false
    }

    handleChange = () => {
        const {dispatch, userId, applicationId} = this.props
        const {checked} = this.state
        updateUserApplication(dispatch, {
            userId,
            applicationId,
            enabled: checked
        })
    }

    render() {
        const {checked, applicationName} = this.props
        return (
            <div className="row">
                <div className="col-6">
                    {applicationName}
                </div>
                <div className="col-6">
                    <InputSwitch
                        checked={checked}
                        onChange={() => {this.setState(
                            {checked: !checked},
                            this.handleChange
                        )}}/>
                </div>
            </div>
        )
    }
}


Toggle.propTypes = {
    dispatch: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    applicationId: PropTypes.number.isRequired,
    applicationName: PropTypes.string.isRequired,
}

export default connect() (Toggle)