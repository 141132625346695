import {getUsers, updateUserMailPriorityApi} from '../../apis/admin/users'


const UsersActions = {
    REQUEST_USERS: 'API_ADMIN_USERS_LIST_REQUEST',
    RECEIVE_USERS: 'API_ADMIN_USERS_LIST_RECEIVE',
    ADD_USER: 'API_ADMIN_USER_ADD',
    UPDATE_USER: 'API_ADMIN_USER_UPDATE',
    REMOVE_USER: 'API_ADMIN_USER_REMOVE',
    UPDATE_USER_MAIL_PRIORITY: 'API_USER_MAIL_PRIORITY',
}

const requestUsers = () => ({
    type: UsersActions.REQUEST_USERS,
})

const receiveUsers = (users) => ({
    type: UsersActions.RECEIVE_USERS,
    users
})

const addUser = (user) => ({
    type: UsersActions.ADD_USER,
    user
})

const updateUser = (user) => ({
    type: UsersActions.UPDATE_USER,
    user
})

const removeUser = (userId) => ({
    type: UsersActions.REMOVE_USER,
    userId
})

const updateUserMailPriorityAction = (userId) => {
    return {
        type: UsersActions.UPDATE_USER_MAIL_PRIORITY,
        userId
    }
}

const updateUserMailPriority = () => (dispatch, getState) => {
    updateUserMailPriorityApi(getState().getIn(['account', 'id']))
    return dispatch(updateUserMailPriorityAction(getState().getIn(['account', 'id'])))
}

const fetchUsers = () => (dispatch) => {
    dispatch(requestUsers())
    return getUsers().then(users => dispatch(receiveUsers(users)))
}

const shouldFetchUsers = (state) => {
    const users = state.getIn(['admin', 'users'])
    return !users || !users.get('isFetching')
}

const fetchUsersIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchUsers(getState())) {
        return dispatch(fetchUsers())
    }
}

export {
    UsersActions,
    fetchUsersIfNeeded,
    updateUserMailPriority,
    addUser,
    updateUser,
    removeUser,
}
