import * as PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import * as immutable from 'immutable'

import {fetchNotificationTemplatesIfNeeded} from '../../../actions/admin/notificationTemplates'
import Row from './Row'
import Details from './Edit'


class List extends React.Component {
    state = {
        selectedNotificationTemplate: null
    }
    
    componentDidMount() {
        const {dispatch} = this.props
        dispatch(fetchNotificationTemplatesIfNeeded())
    }

    getNotificationTemplates = () => {
        const {notificationTemplates} = this.props
        return notificationTemplates.map((notificationTemplate, index) => 
            <Row
                key={index}
                notificationTemplate={notificationTemplate}
                onDetailsClick={() => this.setState({selectedNotificationTemplate: notificationTemplate})}/>
        )
    }

    render() {
        const {isFetchingNotificationTemplates} = this.props
        if (isFetchingNotificationTemplates) {
            return null
        }
        
        const {selectedNotificationTemplate} = this.state
        return (
            <div>
                <h4 className="my-3">Notification Templates</h4>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th className="align-middle">Type</th>
                            <th className="align-middle text-center">Email Delivery?</th>
                            <th className="align-middle">Body</th>
                            <th className="align-middle text-center">In App Delivery?</th>
                            <th className="align-middle" colSpan={2}>Body</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getNotificationTemplates()}
                    </tbody>
                </table>
                {
                    selectedNotificationTemplate &&
                    <Details
                        notificationTemplate={selectedNotificationTemplate}
                        onClose={() => this.setState({selectedNotificationTemplate: null})}/>
                }
            </div>
        )
    }
}


List.propTypes = {
    notificationTemplates: PropTypes.instanceOf(immutable.List).isRequired,
    isFetchingNotificationTemplates: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const notificationTemplates = state.getIn(['admin', 'notificationTemplates'])
        || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        notificationTemplates: notificationTemplates.get('list'),
        isFetchingNotificationTemplates: notificationTemplates.get('isFetching')
    }
}

export default connect(mapStateToProps)(List)
