import {getUserApps, updateUserApps} from '../../apis/admin/userApplications'
import * as immutable from 'immutable'


const UserAppsActions = {
    REQUEST_USER_APPS: 'API_ADMIN_USER_APPS_LIST_REQUEST',
    RECEIVE_USER_APPS: 'API_ADMIN_USER_APPS_LIST_RECEIVE',
    UPDATE_USER_APP: 'API_ADMIN_USER_APP_UPDATE',
}

const requestUserApps = () => ({
    type: UserAppsActions.REQUEST_USER_APPS,
})

const receiveUserApps = (userApps) => ({
    type: UserAppsActions.RECEIVE_USER_APPS,
    userApps
})

const updateUserApp = ({userId, applicationId, enabled}) => {
    return {
        type: UserAppsActions.UPDATE_USER_APP,
        userId, applicationId, enabled
    }
}

const updateUserApplication = (dispatch, {userId, applicationId, enabled}) => {
    updateUserApps({userId, applicationId, enabled})
    return dispatch(updateUserApp({userId, applicationId, enabled}))
}

const fetchUserApps = (userId) => (dispatch) => {
    dispatch(requestUserApps())
    return getUserApps(userId).then(userApps => dispatch(receiveUserApps(userApps)))
}

const shouldFetchUserApps = (state) => {
    const userApps = state.getIn(['admin', 'userApps'])
    return !userApps || !userApps.get('isFetching')
}

const fetchUserAppsIfNeeded = (userId) => (dispatch, getState) => {
    if (shouldFetchUserApps(getState())) {
        return dispatch(fetchUserApps(userId))
    }
}

export {
    UserAppsActions,
    fetchUserAppsIfNeeded,
    updateUserApplication,
}
