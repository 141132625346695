const NotificationType = {
    1: 'Subscription Renewal',
    2: 'Failed Payment',
    3: 'New Invoice',
    4: 'Non System'
}

const DeliveryType = {
    1: 'In App',
    2: 'Email'
}

export {
    NotificationType,
    DeliveryType
}