import * as immutable from 'immutable'

import config from '../../../config/config'
import {get} from '../handler'


const getSubscriptions = () => {
    return get(`${config.apiUrl}/subscriptions`).then((response) => {
        return immutable.fromJS(response.data)
    }).catch(() => {return immutable.List()})
}

export {
    getSubscriptions
}
