import * as immutable from 'immutable'

import config from '../../../config/config'
import {get, put, post, remove} from '../handler'


const getApplications = () => {
    return get(`${config.apiUrl}/applications`).then((response) => {
        return immutable.fromJS(response.data)
    }).catch(() => {return immutable.List()})
}

const updateApplicationApi = ({id, categories, tags, name, description, buyUrl, replyUrl, url, image}) => {
    const params = new FormData()
    params.append('name', name)
    params.append('description', description)
    params.append('buyUrl', buyUrl)
    params.append('categories', categories)
    params.append('tags', tags)
    params.append('replyUrl', replyUrl)
    params.append('url', url)
    if (image) {
        params.append('image', image, image.filename)
    }

    return put(`${config.apiUrl}/applications/${id}`, params).then((response) => {
        return immutable.Map(response.data)
    })
}

const createApplicationApi = ({categories, name, description, buyUrl, tags, replyUrl, url, image}) => {
    const params = new FormData()
    params.append('name', name)
    params.append('description', description)
    params.append('buyUrl', buyUrl)
    params.append('categories', categories)
    params.append('tags', tags)
    params.append('replyUrl', replyUrl)
    params.append('url', url)
    params.append('image', image, image.filename)

    return post(`${config.apiUrl}/applications/`, params).then((response) => {
        return immutable.Map(response.data)
    })
}

const deleteApplicationApi = (applicationId) => {
    return remove(`${config.apiUrl}/applications/${applicationId}`).then((response) => {
        return response.data
    })
}

export {
    getApplications,
    updateApplicationApi,
    createApplicationApi,
    deleteApplicationApi,
}
