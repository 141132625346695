import * as PropTypes from 'prop-types'
import React from 'react'
import {Redirect} from 'react-router-dom'
import {Route, Switch} from 'react-router'
import {connect} from 'react-redux'

import AccountDetails from './payments/AccountDetails'
import Notifications from './notifications/Notifications'
import Subscriptions from './subscriptions/Subscriptions'
import TabsNavigation from './TabsNavigation'
import UserProfile from './profile/UserProfile'
import routes from '../../utils/routes'
import {buildUrl, myAccountTabs} from '../../utils/routes'


class MyAccount extends React.Component {
    render() {
        let {currentTab} = this.props
        if (!currentTab) {
            return <Redirect to={buildUrl(routes.myAccount, {tab: myAccountTabs.userProfile})}/>
        }
        return (
            <div className="my-3">
                <TabsNavigation currentTab={currentTab}/>
                <div className='card p-3'>
                    <Switch>
                        <Route exact path={routes.userProfile} component={UserProfile}/>
                        <Route exact path={routes.subscriptions} component={Subscriptions}/>
                        <Route exact path={routes.accountDetails} component={AccountDetails}/>
                        <Route exact path={routes.notifications} component={Notifications}/>
                    </Switch>
                </div>
            </div>
        )
    }
}


MyAccount.propTypes = {
    currentTab: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => ({
    currentTab: ownProps.match.params.tab
})

export default connect(mapStateToProps)(MyAccount)
