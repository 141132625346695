import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'
import moment from 'moment'


class Row extends React.Component {
    render() {
        const {payment} = this.props

        const subscriptions = payment.get('subscriptions').map((subscription, index) =>
            <React.Fragment key={index}>{subscription.get('title')}<br/></React.Fragment>
        )
        console.log(payment.get('invoiceUrl'))
        return (
            <tr className="d-flex">
                <td className="col-3 font-weight-normal">{moment(payment.get('date')).format('Do MMM YYYY HH:mm')}</td>
                <td className="col-3 font-weight-normal">{subscriptions}</td>
                <td className="col-2 font-weight-normal text-center">{payment.get('price')}</td>
                <td className="col-4 font-weight-normal">
                    {payment.get('invoiceUrl')
                    ? <a className="" target="_blank" rel="noopener noreferrer" href={payment.get('invoiceUrl')}>Download Receipt</a>
                    : 'Not available'
                    }                    
                </td>
            </tr>
        )
    }
}


Row.propTypes = {
    payment: PropTypes.instanceOf(immutable.Map).isRequired,
}

export default Row