import * as immutable from 'immutable'

import config from '../../../config/config'
import {get} from '../handler'


const getPayments = () => {
    return get(`${config.apiUrl}/payments`).then((response) => {
        return immutable.fromJS(response.data)
    }).catch(() => {return immutable.List()})
}

export {
    getPayments
}
