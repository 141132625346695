import * as PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import * as immutable from 'immutable'
import {Navbar, NavbarBrand, NavbarToggler, NavLink} from 'mdbreact'

import routes from '../../utils/routes'
import AdminNavBar from './AdminNavBar'
import UserNavBar from './UserNavBar'
import LogOut from './LogOut'

class Header extends React.Component {
    state = {
        isOpen: false,
        logOut: null,
    }

    toggleCollapse = () => this.setState({isOpen: !this.state.isOpen})

    render() {
        const {account} = this.props
        const {isOpen, logOut} = this.state

        return (
            <header>
                <Navbar color="bg-header" dark expand="md">
                    <NavbarBrand>
                        <NavLink to={routes.index}>
                            <img src="/assets/img/logo_white.svg" height="38"/>
                        </NavLink>
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggleCollapse}/>
                    <React.Fragment>
                        {
                            account.get('isAdmin') &&
                            <AdminNavBar
                                isOpen={isOpen}
                                show={account.get('isAdmin') === true}
                                userName={account.get('name')}
                                onLogOutClick={() => this.setState({logOut: true})}/>
                        }
                        {
                            !account.get('isAdmin') &&
                            <UserNavBar
                                isOpen={isOpen}
                                show={account.get('isAdmin') === false}
                                userName={account.get('name')}
                                onLogOutClick={() => this.setState({logOut: true})}/>
                        }
                        {
                            logOut &&
                            <LogOut onClose={() => this.setState({logOut: null})}/>
                        }
                    </React.Fragment>
                </Navbar>
            </header>
        )
    }
}

Header.propTypes = {
    account: PropTypes.instanceOf(immutable.Map),
}

export default Header
