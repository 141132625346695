import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, InputSwitch} from 'mdbreact'
import * as PropTypes from 'prop-types'
import * as immutable from 'immutable'
import {connect} from 'react-redux'

import {NotificationType} from '../../../utils/enums'
import {updateNotificationTemplate} from '../../../actions/admin/notificationTemplates'


class Edit extends React.Component {
    state = {
        id: this.props.notificationTemplate.get('id'),
        inAppDelivery: this.props.notificationTemplate.get('inAppDelivery'),
        emailDelivery: this.props.notificationTemplate.get('emailDelivery'),
    }

    handleChange = () => {
        const {dispatch} = this.props
        updateNotificationTemplate(dispatch, this.state)
    }

    render() {
        const {onClose, notificationTemplate} = this.props
        const {inAppDelivery, emailDelivery} = this.state

        return (
            <Modal isOpen toggle={onClose}>
                <ModalBody>
                    <h5 className="py-2 border-bottom">
                        {`${NotificationType[notificationTemplate.get('type')]} Template`}
                    </h5>
                    <div className="row">
                        <div className="col-6">
                            Email Delivery
                        </div>
                        <div className="col-6">
                            <InputSwitch
                                checked={emailDelivery}
                                onChange={() => {this.setState({emailDelivery: !emailDelivery}, this.handleChange)}}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            In App Delivery
                        </div>
                        <div className="col-6">
                            <InputSwitch
                                checked={inAppDelivery}
                                onChange={() => {this.setState({inAppDelivery: !inAppDelivery}, this.handleChange)}}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={onClose}>close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}


Edit.propTypes = {
    notificationTemplate: PropTypes.instanceOf(immutable.Map).isRequired,
    onClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
}

export default connect()(Edit)
