import pathToRegexp from 'path-to-regexp'

const reportTabs = {
    revenues: 'revenues',
    subscriptions: 'subscriptions',
    failedPayments: 'failed-payments',
}

const myAccountTabs = {
    userProfile: 'profile',
    subscriptions: 'subscriptions',
    accountDetails: 'details',
    notifications: 'notifications',
}

let myAccountTabsValues = []
for (const [_, value] of Object.entries(myAccountTabs)) {
    myAccountTabsValues.push(value)
}

let reportTabsValues = []
for (const [_, value] of Object.entries(reportTabs)) {
    reportTabsValues.push(value)
}

const routes = {
    index: '/',
    logout: '/logout/',
    customSsoZendesk: '/sso/zendesk',
    forgotPassword: '/forgot-password/',
    updatePassword: '/update-password/',
    dashboard: '/dashboard/',
    myAccount: '/my-account/',
    myAccountTabs: `/my-account/:tab(${myAccountTabsValues.join('|')})?/`,
    userProfile: '/my-account/profile/',
    subscriptions: '/my-account/subscriptions/',
    accountDetails: '/my-account/details/',
    notifications: '/my-account/notifications/',
    admin: '/admin/',
    adminApplications: '/admin/applications/',
    adminNotificationTemplates: '/admin/notification-templates/',
    adminUsers: '/admin/users/',
    adminReports: '/admin/reports/',
    adminReportsTabs: `/admin/reports/:tab(${reportTabsValues.join('|')})?/`,
    adminReportRevenues: '/admin/reports/revenues/',
    adminReportSubscriptions: '/admin/reports/subscriptions/',
    adminReportFailedPayments: '/admin/reports/failed-payments/',
}

const buildUrl = (template, params) => {
    params = params || {}
    const toPath = pathToRegexp.compile(template)
    return toPath(params)
}

export {
    buildUrl,
    myAccountTabs,
}

export default routes
