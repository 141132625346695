import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'
import {Switch, Route, Redirect} from 'react-router'

import Applications from './dashboard/Applications'
import MyAccount from './my-account/MyAccount'
import routes from '../utils/routes'


class LoggedUserRoot extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path={routes.myAccountTabs} component={MyAccount}/>
                <Route exact path={routes.dashboard} component={Applications}/>
                <Redirect to={routes.dashboard}/>
            </Switch>
        )
    }
}


LoggedUserRoot.propTypes = {
    account: PropTypes.instanceOf(immutable.Map).isRequired,
}

export default LoggedUserRoot
