import React from 'react'
import b2cauth from 'react-azure-adb2c'


class AutoLogOut extends React.Component {
    state = {
        loading: false
    }

    componentDidMount() {
        b2cauth.signOut()
    }

    render() {
        return (
            <div></div>
        )
    }
}


AutoLogOut.propTypes = {
}

export default AutoLogOut
