import * as immutable from 'immutable'

import {UserAppsActions} from '../../actions/admin/userApplications'


export default (state = null, action) => {
    switch (action.type) {
        case UserAppsActions.REQUEST_USER_APPS:
            state = immutable.Map({
                isFetching: true,
                list: immutable.List()
            })
            break
        case UserAppsActions.RECEIVE_USER_APPS:
            state = immutable.Map({
                isFetching: false,
                list: action.userApps
            })
            break
        case UserAppsActions.UPDATE_USER_APP:
            state = immutable.Map({
                isFetching: state.get('isFetching'),
                list: state.get('list').map((userApp) => {
                    if (userApp.get('userId') === action.userId
                        && userApp.get('applicationId') === action.applicationId) {
                        return userApp
                            .set('enabled', action.enabled)
                    }
                    return userApp
                })
            })
            break
        default:
            break
    }
    return state
}
