import * as PropTypes from 'prop-types'
import React from 'react'
import {MDBCollapse, MDBNavbarNav, MDBNavItem, MDBNavLink} from 'mdbreact'
import * as immutable from 'immutable'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'

import routes, {buildUrl} from '../../utils/routes'
import {fetchNotificationsIfNeeded} from '../../actions/notifications'
import NotificationsBell from './NotificationsBell'


class UserNavBar extends React.Component {
    componentDidMount() {
        const {dispatch} = this.props
        dispatch(fetchNotificationsIfNeeded())
    }

    render() {
        const {show, isOpen, notifications, userName, onLogOutClick} = this.props
        const pathname = window.location.pathname

        if (!show) {
            return null
        }

        return (
            <MDBCollapse isOpen={isOpen} navbar>
                <MDBNavbarNav left>
                    <MDBNavItem active={pathname === buildUrl(routes.dashboard)}>
                        <MDBNavLink to={buildUrl(routes.dashboard)}>Applications</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem active={pathname.includes(buildUrl(routes.myAccount))}>
                        <MDBNavLink to={buildUrl(routes.userProfile)}>My Account</MDBNavLink>
                    </MDBNavItem>
                </MDBNavbarNav>
                <MDBNavbarNav right className="nav-flex-icons">
                    <MDBNavItem className="my-auto font-weight-bolder">
                        <MDBNavLink to={buildUrl(routes.userProfile)}>{userName}</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <NotificationsBell notifications={notifications}/>
                    </MDBNavItem>
                    <MDBNavItem className="my-auto pointer" onClick={onLogOutClick}>
                        <span className="fa-stack light-grey-ic">
                            <i className="fa fa-circle fa-stack-2x"/>
                            <i className="fas fa-sign-out-alt fa-stack-1x fa-inverse"/>
                        </span>
                    </MDBNavItem>
                </MDBNavbarNav>
            </MDBCollapse>
        )
    }
}


UserNavBar.propTypes = {
    show: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    userName: PropTypes.string.isRequired,
    notifications: PropTypes.instanceOf(immutable.List).isRequired,
    isFetchingNotifications: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    onLogOutClick: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const notifications = state.get('notifications')
        || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        notifications: notifications
            .get('list')
            .filter((notification) => notification.get('read') === false && notification.get('deliveryType') === 1)
            .sortBy((notification) => notification.get('created'))
            .reverse(),
        isFetchingNotifications: notifications.get('isFetching')
    }
}

export default withRouter(connect(mapStateToProps)(UserNavBar))