import React from 'react'
import {Switch, Route} from 'react-router'
import authentication from 'react-azure-adb2c'

import ForgotPassword from './account/ForgotPassword'
import LoggedRoot from './LoggedRoot'
import UpdatePassword from './account/UpdatePassword'
import AutoLogout from './account/AutoLogout'
import routes from '../utils/routes'
import CustomSsoZendesk from './CustomSsoZendesk'


class Root extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path={routes.updatePassword} component={UpdatePassword}/>
                    <Route exact path={routes.logout} component={authentication.required(AutoLogout)}/>
                    <Route exact path={routes.forgotPassword} component={ForgotPassword}/>
                    <Route exact path={routes.customSsoZendesk} component={authentication.required(CustomSsoZendesk)}/>
                    <Route component={authentication.required(LoggedRoot)}/>
                </Switch>
            </React.Fragment>
        )
    }
}

export default Root
