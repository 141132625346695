import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'
import moment from 'moment'


const DetailsActive = ({application}) => {
    return (
        <div className="m-3 border border-info col-6">
            <dl className="dl-horizontal">
                <dt>Subscription Title</dt>
                <dd>{application.getIn(['subscription', 'title'])}</dd>
                <dt>Description</dt>
                <dd>{application.getIn(['subscription', 'description'])}</dd>
                <dt>Expires</dt>
                <dd>{moment(application.getIn(['subscription', 'expiration'])).format('Do MMM YYYY')}</dd>
            </dl>
        </div>
    )
}


DetailsActive.propTypes = {
    application: PropTypes.instanceOf(immutable.Map).isRequired,
}

export default DetailsActive
