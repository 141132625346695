import * as immutable from 'immutable'

import {NotificationsActions} from '../actions/notifications'


export default (state = null, action) => {
    switch (action.type) {
        case NotificationsActions.REQUEST_NOTIFICATIONS:
            state = immutable.Map({
                isFetching: true,
                list: immutable.List()})
            break
        case NotificationsActions.RECEIVE_NOTIFICATIONS:
            state = immutable.Map({
                isFetching: false,
                list: action.notifications
            })
            break
        case NotificationsActions.UPDATE_NOTIFICATION:
            state = immutable.Map({
                isFetching: state.get('isFetching'),
                list: state.get('list').map((notification) => {
                    if (notification.get('id') === action.notificationId) {
                        const read = notification.get('read')
                        return notification.set('read', !read)
                    }
                    return notification
                })
            })
            break
        default:
            break
    }
    return state
}
