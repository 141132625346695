import React from 'react'
import classNames from 'classnames'

import {updatePassword} from '../../apis/accounts'

class UpdatePassword extends React.Component {
    constructor(props) {
        super(props)

        var paths = window.location.pathname.split('/')

        var userId = paths[paths.length - 2]
        var token = paths[paths.length - 1]

        this.state = {
            userId: atob(userId),
            token: token,
            password: null,
            error: null,
            loading: false,
            passwordUpdated: false,
            invalidToken: (paths.length != 4)
        }
    }

    onUpdatePassword = (e) => {
        e.preventDefault()
        this.setState({error: null, loading: true})
        const {userId, token, password} = this.state
        updatePassword(userId, token, password).then((response) => {
            if(response.get('success')) {
                this.setState({
                    passwordUpdated: true
                })
            }
            else {
                this.setState({
                    error: response.get('reason')
                })
            }
        })
    }

    render() {
        const {loading, invalidToken, passwordUpdated, error} = this.state

        if(error) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto my-3">
                            <div className="card">
                                <div className="card-body">
                                    <form className="form-signin my-3">
                                        <div>
                                            <img src="/assets/img/logo_colour.svg" height="30"/>
                                        </div>
                                        <span className="mt-2 d-block">Custmomer Portal</span>
                                        <label className="mt-2 d-block">{error}</label>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if(invalidToken) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto my-3">
                            <div className="card">
                                <div className="card-body">
                                    <form className="form-signin my-3">
                                        <div>
                                            <img src="/assets/img/logo_colour.svg" height="30"/>
                                        </div>
                                        <span className="mt-2 d-block">Custmomer Portal</span>
                                        <label className="mt-2 d-block">Invalid Token</label>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if(passwordUpdated) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto my-3">
                            <div className="card">
                                <div className="card-body">
                                    <form className="form-signin my-3">
                                        <div>
                                            <img src="/assets/img/logo_colour.svg" height="30"/>
                                        </div>
                                        <span className="mt-2 d-block">Custmomer Portal</span>
                                        <label className="mt-2 d-block">Your password has been updated please continue to the <a href="/">login page</a></label>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mx-auto my-3">
                        <div className="card">
                            <div className="card-body">
                                <form className="form-signin my-3">
                                    <div>
                                        <img src="/assets/img/logo_colour.svg" height="30"/>
                                    </div>
                                    <span className="mt-2 d-block">Custmomer Portal</span>
                                    <label className="h3 mb-3 font-weight-light small">Please fill in your new password</label>
                                    <label htmlFor="password" className="sr-only">Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        className="form-control"
                                        placeholder="Password"
                                        required
                                        onChange={(event) => this.setState({password: event.currentTarget.value})}/>
                                    <button className="btn btn-primary btn-block" onClick={this.onUpdatePassword} disabled={loading}>
                                        <div className={classNames({'spinner-border spinner-border-sm': loading})} role="status">
                                            <span className={classNames({'sr-only': loading})}>Update Password</span>
                                        </div>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UpdatePassword
