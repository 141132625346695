import * as immutable from 'immutable'

import {PaymentsActions} from '../actions/payments'


export default (state = null, action) => {
    switch (action.type) {
        case PaymentsActions.REQUEST_PAYMENTS:
            state = immutable.Map({
                isFetching: true,
                list: immutable.List()})
            break
        case PaymentsActions.RECEIVE_PAYMENTS:
            state = immutable.Map({
                isFetching: false,
                list: action.payments
            })
            break
        default:
            break
    }
    return state
}
