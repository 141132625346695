import * as PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import * as immutable from 'immutable'
import moment from 'moment'

import {fetchNotificationsIfNeeded} from '../../../actions/notifications'
import {MDBDataTable} from 'mdbreact'
import {DeliveryType, NotificationType} from '../../../utils/enums'
import ActionsRow from './ActionsRow'

const columns = [
    {
        label: 'Date',
        field: 'date',
        sort: 'asc',
    },
    {
        label: 'Type',
        field: 'type',
        sort: 'asc',
    },
    {
        label: 'Delivery',
        field: 'delivery',
        sort: 'asc',
    },
    {
        label: 'Details',
        field: 'details',
        sort: 'asc',
    },
    {
        label: 'Actions',
        field: 'actions',
    },
]


class Notifications extends React.Component {
    componentDidMount() {
        const {dispatch} = this.props
        dispatch(fetchNotificationsIfNeeded())
    }

    getNotifications = () => {
        const {notifications} = this.props
        return notifications.map((notification, index) => (
            <Row
                key={index}
                notification={notification}/>
        ))
    }

    renderActions = (notification) => {
        return (
            <ActionsRow notification={notification}/>
        )
    }

    render() {
        const {isFetchingNotifications, notifications} = this.props

        if (isFetchingNotifications) {
            return null
        }

        if (notifications.size === 0) {
            return (
                <div className="alert alert-primary m-0">
                    <h4 className="alert-heading">Notifications</h4>
                    <hr/>
                    <p className="mb-0">
                        There are no notifications.
                    </p>
                </div>
            )
        }

        const dataToShow = {
            columns,
            rows: Array.from(notifications.map((notification) => {
                return {
                    date: moment.utc(notification.get('created')).format('Do MMM YYYY HH:mm'),
                    type: NotificationType[notification.get('notificationType')],
                    delivery: DeliveryType[notification.get('deliveryType')],
                    details: notification.get('details'),
                    actions: this.renderActions(notification)
                }
            }))
        }

        return (
            <React.Fragment>
                <h4 className="mb-3">Notifications</h4>
                <MDBDataTable
                    striped
                    bordered
                    hover={true}
                    searching={true}
                    data={dataToShow}/>
            </React.Fragment>
        )
    }
}

Notifications.propTypes = {
    notifications: PropTypes.instanceOf(immutable.List).isRequired,
    isFetchingNotifications: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const notifications = state.get('notifications')
        || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        notifications: notifications
            .get('list')
            .sort((notA, notB) => {
                const notAM = moment.utc(notA.get('created'))
                const notBM = moment.utc(notB.get('created'))
                if (notAM < notBM) { return 1 }
                if (notAM > notBM) { return -1 }
                if (notAM === notBM) { return 0 }
            }),
        isFetchingNotifications: notifications.get('isFetching')
    }
}

export default connect(mapStateToProps)(Notifications)
