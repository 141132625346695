import React from 'react'
import * as PropTypes from 'prop-types'
import * as immutable from 'immutable'
import {connect} from 'react-redux'

import {fetchPaymentsIfNeeded} from '../../../actions/payments'
import Row from './Row'
import moment from '../notifications/Notifications'


class AccountDetails extends React.Component {
    componentDidMount() {
        const {dispatch} = this.props
        dispatch(fetchPaymentsIfNeeded())
    }

    getPayments = () => {
        const {payments} = this.props
        return payments.map((payment, index) => (
            <Row
                key={index}
                payment={payment}/>
        ))
    }

    render() {
        const {isFetchingPayments, payments} = this.props

        if (isFetchingPayments) {
            return null
        }

        if (payments.size === 0) {
            return (
                <div className="alert alert-primary m-0">
                    <h4 className="alert-heading">Payments</h4>
                    <hr/>
                    <p className="mb-0">
                        There are no payments.
                    </p>
                </div>
            )
        }

        return (
            <React.Fragment>
                <h4 className="mb-3">Account Details</h4>
                <table className="table table-hover">
                    <thead>
                        <tr className="d-flex">
                            <th className="col-3">Payment Date</th>
                            <th className="col-3">Subscription</th>
                            <th className="col-2 text-center">Amount</th>
                            <th className="col-4">Receipt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getPayments()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}


AccountDetails.propTypes = {
    payments: PropTypes.instanceOf(immutable.List).isRequired,
    isFetchingPayments: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const payments = state.get('payments')
        || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        payments: payments.get('list')
            .sort((payA, payB) => {
                const payAM = moment.utc(payA.get('created'))
                const payBM = moment.utc(payB.get('created'))
                if (payAM < payBM) { return 1 }
                if (payAM > payBM) { return -1 }
                if (payAM === payBM) { return 0 }
            }),
        isFetchingPayments: payments.get('isFetching')
    }
}

export default connect(mapStateToProps)(AccountDetails)
