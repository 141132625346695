import * as PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import * as immutable from 'immutable'
import moment from 'moment'
import {MDBDataTable} from 'mdbreact'

import ExportCSVIcon from '../../../buttons/ExportCSVIcon'
import ExportPDFIcon from '../../../buttons/ExportPDFIcon'
import {fetchSubscriptionsIfNeeded} from '../../../../actions/admin/subscriptions'

const columns = [
    {
        label: 'Firm',
        field: 'firm',
        sort: 'asc',
    },
    {
        label: 'User',
        field: 'user',
        sort: 'asc',
    },
    {
        label: 'Package',
        field: 'package',
        sort: 'asc',
    },
    {
        label: 'Renewal Date',
        field: 'renewal_date',
        sort: 'asc',
    },
]


class List extends React.Component {
    componentDidMount() {
        const {dispatch} = this.props
        dispatch(fetchSubscriptionsIfNeeded())
    }

    render() {
        const {isFetchingSubscriptions, subscriptions} = this.props

        if (isFetchingSubscriptions) {
            return null
        }

        if (subscriptions.size === 0) {
            return (
                <React.Fragment>
                    <div className="alert alert-primary mt-3">
                        <h4 className="alert-heading">Subscriptions</h4>
                        <hr/>
                        <p className="mb-0">
                            There are no records.
                        </p>
                    </div>
                </React.Fragment>
            )
        }

        const dataToExport = Array.from(subscriptions.map((subscription) => {
            return {
                firm: subscription.getIn(['user', 'company']),
                user: subscription.getIn(['user', 'name']),
                package: subscription.get('title'),
                renewal_date: moment.utc(subscription.get('expiration')).format('DD/MM/YYYY HH:mm'),
            }
        }))

        const dataToShow = {columns, rows: dataToExport}

        return (
            <div>
                <h4 className="my-3">
                    Subscriptions
                    <ExportCSVIcon
                        data={dataToExport}
                        filename='Subscriptions Report.csv'
                        title='Export to CSV'/>
                    <ExportPDFIcon
                        filename='Subscriptions Report.pdf'
                        title='Export to PDF'/>
                </h4>
                <MDBDataTable
                    striped
                    bordered
                    hover={true}
                    searching={true}
                    data={dataToShow}/>
            </div>
        )
    }
}


List.propTypes = {
    subscriptions: PropTypes.instanceOf(immutable.List).isRequired,
    isFetchingSubscriptions: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const subscriptions = state.getIn(['admin', 'subscriptions'])
        || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        subscriptions: subscriptions.get('list')
            .sort((subsA, subsB) => {
                const subsAM = moment.utc(subsA.get('created'))
                const subsBM = moment.utc(subsB.get('created'))
                if (subsAM < subsBM) {
                    return -1
                }
                if (subsAM > subsBM) {
                    return 1
                }
                if (subsAM === subsBM) {
                    return 0
                }
            }),
        isFetchingSubscriptions: subscriptions.get('isFetching'),
    }
}

export default connect(mapStateToProps)(List)
