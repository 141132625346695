import {getSubscriptions} from '../../apis/admin/subscriptions'


const SubscriptionsActions = {
    REQUEST_SUBSCRIPTIONS: 'API_ADMIN_SUBSCRIPTIONS_LISTS_REQUEST',
    RECEIVE_SUBSCRIPTIONS: 'API_ADMIN_SUBSCRIPTIONS_LISTS_RECEIVE',
}

const requestSubscriptions = () => ({
    type: SubscriptionsActions.REQUEST_SUBSCRIPTIONS,
})

const receiveSubscriptions = (subscriptions) => ({
    type: SubscriptionsActions.RECEIVE_SUBSCRIPTIONS,
    subscriptions
})

const fetchSubscriptions = () => (dispatch) => {
    dispatch(requestSubscriptions())
    return getSubscriptions().then(subscriptions => dispatch(receiveSubscriptions(subscriptions)))
}

const shouldFetchSubscriptions = (state) => {
    const subscriptions = state.getIn(['admin', 'subscriptions'])
    return !subscriptions || !subscriptions.get('isFetching')
}

const fetchSubscriptionsIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchSubscriptions(getState())) {
        return dispatch(fetchSubscriptions())
    }
}

export {
    SubscriptionsActions,
    fetchSubscriptionsIfNeeded,
}
