import {getApplications} from '../apis/applications'


const ApplicationsActions = {
    REQUEST_APPLICATIONS: 'API_APPLICATIONS_LISTS_REQUEST',
    RECEIVE_APPLICATIONS: 'API_APPLICATIONS_LISTS_RECEIVE',
}

const requestApplications = () => ({
    type: ApplicationsActions.REQUEST_APPLICATIONS,
})

const receiveApplications = (applications) => ({
    type: ApplicationsActions.RECEIVE_APPLICATIONS,
    applications
})

const fetchApplications = (userId) => (dispatch) => {
    dispatch(requestApplications())
    return getApplications(userId).then(applications => dispatch(receiveApplications(applications)))
}

const shouldFetchApplications = (state) => {
    const applications = state.get('applications')
    return !applications || !applications.get('isFetching')
}

const fetchApplicationsIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchApplications(getState())) {
        return dispatch(fetchApplications(getState().getIn(['account', 'id'])))
    }
}

export {
    ApplicationsActions,
    fetchApplicationsIfNeeded,
}
