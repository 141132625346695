import * as immutable from 'immutable'

import {UsersActions} from '../../actions/admin/users'


export default (state = null, action) => {
    switch (action.type) {
        case UsersActions.REQUEST_USERS:
            state = immutable.Map({
                isFetching: true,
                list: immutable.List()
            })
            break
        case UsersActions.RECEIVE_USERS:
            state = immutable.Map({
                isFetching: false,
                list: action.users
            })
            break
        case UsersActions.ADD_USER:
            state = immutable.Map({
                isFetching: state.get('isFetching'),
                list: state.get('list').push(action.user)
            })
            break
        case UsersActions.REMOVE_USER:
            state = immutable.Map({
                isFetching: state.get('isFetching'),
                list: state.get('list').filter((user) => {
                    return user.get('id') !== action.userId
                })
            })
            break
        case UsersActions.UPDATE_USER:
            state = immutable.Map({
                isFetching: state.get('isFetching'),
                list: state.get('list').map((user) => {
                    if (user.get('id') === action.user.get('id')) {
                        return immutable.Map(action.user)
                    }
                    return user
                })
            })
            break
        case UsersActions.UPDATE_USER_MAIL_PRIORITY:
            state = immutable.Map({
                isFetching: state.get('isFetching'),
                list: state.get('list').map((user) => {
                    if (user.get('id') === action.userId) {
                        const checked = user.get('primaryEmailNotifications')
                        return user
                            .set('primaryEmailNotifications', !checked)
                            .set('secondaryEmailNotifications', checked)
                    }
                    return user
                })
            })
            break
        default:
            break
    }
    return state
}
