import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'
import classNames from 'classnames'

import {NotificationType} from '../../../utils/enums'
import {sendNotificationTemplate} from '../../../apis/admin/notificationTemplates'


class ActionsRow extends React.Component {
    state = {
        isSending: false
    }

    onSendNotificationsClick = () => {
        const {user} = this.props
        Object.keys(NotificationType).map((notificationType) => {
            this.setState(
                {isSending: true},
                () => {
                    sendNotificationTemplate({notificationType: parseInt(notificationType), userId: user.get('id')})
                        .then(() => {
                            this.setState({isSending: false})
                        })
                }
            )
        })
    }

    render() {
        const {user, onDetailsClick, onDeleteClick} = this.props
        const {isSending} = this.state

        return (
            <React.Fragment>
                <i
                    className="fas fa-pencil-alt pointer mx-2"
                    title="User Details"
                    onClick={onDetailsClick}/>
                <i
                    className="far fa-trash-alt pointer mx-2"
                    title="Delete User"
                    onClick={() => onDeleteClick(user.get('id'))}/>
            </React.Fragment>
        )
    }
}


ActionsRow.propTypes = {
    onDetailsClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(immutable.Map).isRequired,
}

export default ActionsRow
