import * as immutable from 'immutable'

import config from '../../../config/config'
import {get} from '../handler'


const getJwtZendesk = (userId, returnTo) => {
    returnTo = returnTo ? returnTo : ''
    return get(`${config.apiUrl}/sso/zendesk/${userId}/?returnTo=${returnTo}`).then((response) => {
        return immutable.fromJS(response.data)
    }).catch((response) => {
        return response
    })
}

export {
    getJwtZendesk,
}
