import * as immutable from 'immutable'

import config from '../../../config/config'
import {put, get, post} from '../handler'


const getNotificationTemplates = () => {
    return get(`${config.apiUrl}/notification-templates`).then((response) => {
        return immutable.fromJS(response.data)
    }).catch(() => {return immutable.List()})
}

const updateNotificationTemplateDelivery = ({id, inAppDelivery, emailDelivery}) => {
    return put(`${config.apiUrl}/notification-templates/${id}`, {
        Id: id,
        InAppDelivery: inAppDelivery,
        EmailDelivery: emailDelivery
    })
}

const sendNotificationTemplate = ({notificationType, userId}) => {
    return post(`${config.apiUrl}/notification-templates/${notificationType}/${userId}`)
}

export {
    getNotificationTemplates,
    updateNotificationTemplateDelivery,
    sendNotificationTemplate
}
