import React from 'react'
import classNames from 'classnames'
import * as PropTypes from 'prop-types'


class CreateIcon extends React.Component {
    state = {
        isHovered: false,
    }

    handleHover = () => {
        this.setState(prevState => ({
            isHovered: !prevState.isHovered
        }))
    }

    render() {
        const {onClick, title} = this.props
        return (
            <i
                onClick={onClick}
                title={title}
                onMouseEnter={this.handleHover}
                onMouseLeave={this.handleHover}
                className={classNames(
                    'ml-3 pointer',
                    {'far fa-plus-square': !this.state.isHovered},
                    {'fas fa-plus-square': this.state.isHovered}
                )}/>
        )
    }
}


CreateIcon.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default CreateIcon
