import * as PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {Button, Modal, ModalBody, ModalFooter} from 'mdbreact'
import {getJwtZendesk} from '../apis/admin/sso'


import {getAccount} from '../apis/handler'
import {setAccount} from '../actions/account'

class CustomSsoZendesk extends React.Component {
    state = {
        account: getAccount()
    }

    componentDidMount() {
        const {dispatch} = this.props
        const {account} = this.state
        dispatch(setAccount(account))

        var urlParams = new URLSearchParams(window.location.search);
        getJwtZendesk(account.get('id'), encodeURI(urlParams.get('return_to')))
            .then((response) => {
                if(response.get('validated')) {
                    window.location = response.get('redirectUrl')
                }
            })
    }
    
    render() {
        return (
            <Modal isOpen>
                <ModalBody>
                    <h3 className="pt-3 pr-2">
                        Signing in
                    </h3>
                    <div className="row">
                        <div className="col-12">
                            <span>Signing in to Omnipro, please wait...</span>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" outline disabled={true}>
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Delete</span>
                        </div>
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

CustomSsoZendesk.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

export default connect(null)(CustomSsoZendesk)