import * as PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import * as immutable from 'immutable'
import {MDBDataTable} from 'mdbreact'
import moment from 'moment'

import {addUser, fetchUsersIfNeeded, removeUser} from '../../../actions/admin/users'
import {fetchApplicationsIfNeeded} from '../../../actions/admin/applications'
import Details from './Edit'
import Create from './Create'
import Delete from './Delete'
import CreateIcon from '../../buttons/CreateIcon'
import ActionsRow from './ActionsRow'

const columns = [
    {
        label: 'Id',
        field: 'id',
        sort: 'asc',
    },
    {
        label: 'Name',
        field: 'name',
        sort: 'asc',
    },
    {
        label: 'Email',
        field: 'email',
        sort: 'asc',
    },
    {
        label: 'Admin',
        field: 'isAdmin',
        sort: 'asc',
    },
    {
        label: 'Created',
        field: 'created',
        sort: 'asc',
    },
    {
        label: 'Modified',
        field: 'modified',
        sort: 'asc',
    },
    {
        label: 'Actions',
        field: 'actions',
    },
]


class List extends React.Component {
    state = {
        selectedUser: null,
        createUser: false,
        deleteUser: null,
    }

    componentDidMount() {
        const {dispatch} = this.props
        dispatch(fetchUsersIfNeeded())
        dispatch(fetchApplicationsIfNeeded())
    }

    handleCreate = (user) => {
        const {dispatch} = this.props
        dispatch(addUser(user))
        this.setState({createUser: false})
    }

    handleDelete = (userId) => {
        const {dispatch} = this.props
        dispatch(removeUser(userId))
        this.setState({deleteUser: null})
    }

    onDetailsClick = (user) => {
        this.setState({selectedUser: user})
    }

    onDeleteClick = (userId) => {
        this.setState({deleteUser: userId})
    }

    renderActions = (user) => {
        return (
            <ActionsRow
                user={user}
                onDetailsClick={() => this.onDetailsClick(user)}
                onDeleteClick={() => this.onDeleteClick(user.get('id'))}/>
        )
    }

    render() {
        const {isFetchingUsers, isFetchingApplications, users, applications} = this.props
        const {selectedUser, createUser, deleteUser} = this.state

        if (isFetchingUsers || isFetchingApplications) {
            return null
        }

        if (users.size === 0) {
            return (
                <React.Fragment>
                    <div className="alert alert-primary mt-3">
                        <h4 className="alert-heading">Users</h4>
                        <hr/>
                        <p className="mb-0">
                            There are no users.
                            <a
                                className="mx-2"
                                onClick={() => this.setState({createUser: true})}>Click here to create the first one.</a>
                        </p>
                    </div>
                    {
                        createUser &&
                        <Create
                            onClose={() => this.setState({createUser: false})}
                            createUser={(user) => this.handleCreate(user)}/>
                    }
                </React.Fragment>
            )
        }

        const dataToShow = {
            columns,
            rows: Array.from(users.map((user) => {
                return {
                    id: user.get('id'),
                    name: user.get('name'),
                    email: user.get('primaryEmailNotifications') === true ? user.get('primaryEmail') : user.get('secondaryEmail'),
                    isAdmin: user.get('isAdmin') ? 'Yes' : 'No',
                    created: moment(user.get('created')).format('Do MMM YYYY HH:mm'),
                    modified: moment(user.get('modified')).format('Do MMM YYYY HH:mm'),
                    actions: this.renderActions(user)
                }
            }))
        }

        return (
            <div>
                <h4 className="my-3">
                    Users
                    <CreateIcon title='Create User' onClick={() => this.setState({createUser: true})}/>
                </h4>
                <MDBDataTable
                    striped
                    bordered
                    hover={true}
                    searching={true}
                    data={dataToShow}/>
                {
                    selectedUser &&
                    <Details
                        user={selectedUser}
                        applications={applications}
                        onClose={() => this.setState({selectedUser: null})}/>
                }
                {
                    createUser &&
                    <Create
                        onClose={() => this.setState({createUser: false})}
                        createUser={(user) => this.handleCreate(user)}/>
                }
                {
                    deleteUser &&
                    <Delete
                        userId={deleteUser}
                        onClose={() => this.setState({deleteUser: null})}
                        deleteUser={(userId) => this.handleDelete(userId)}/>
                }
            </div>
        )
    }
}


List.propTypes = {
    users: PropTypes.instanceOf(immutable.List).isRequired,
    applications: PropTypes.instanceOf(immutable.List).isRequired,
    isFetchingUsers: PropTypes.bool.isRequired,
    isFetchingApplications: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const users = state.getIn(['admin', 'users']) || immutable.Map({isFetching: true, list: immutable.List()})
    const applications = state.getIn(['admin', 'applications']) || immutable.Map({
        isFetching: true,
        list: immutable.List()
    })

    return {
        users: users.get('list')
            .sort((userA, userB) => {
                if (userA.get('id') < userB.get('id')) {
                    return -1
                }
                if (userA.get('id') > userB.get('id')) {
                    return 1
                }
                if (userA.get('id') === userB.get('id')) {
                    return 0
                }
            }),
        isFetchingUsers: users.get('isFetching'),

        applications: applications.get('list'),
        isFetchingApplications: applications.get('isFetching')
    }
}

export default connect(mapStateToProps)(List)
