import * as PropTypes from 'prop-types'
import React from 'react'
import * as immutable from 'immutable'
import NavItem from './NavItem'


const NavList = ({activeApplication, applications, onClick}) => {
    const getNavItems = () => {
        return applications.map((app, index) => 
            <NavItem
                key={index} 
                application={app} 
                onClick={onClick} 
                isActive={activeApplication && activeApplication.get('name') === app.get('name')}/>
        )
    }
    
    return (
        <div className="col-3 pointer">
            {getNavItems()}
        </div>
    )
}


NavList.propTypes = {
    activeApplication: PropTypes.instanceOf(immutable.Map),
    applications: PropTypes.instanceOf(immutable.List).isRequired,
    onClick: PropTypes.func.isRequired,
}

export default NavList
