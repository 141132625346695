import {getPayments} from '../../apis/admin/payments'


const PaymentsActions = {
    REQUEST_PAYMENTS: 'API_ADMIN_PAYMENTS_LISTS_REQUEST',
    RECEIVE_PAYMENTS: 'API_ADMIN_PAYMENTS_LISTS_RECEIVE',
}

const requestPayments = () => ({
    type: PaymentsActions.REQUEST_PAYMENTS,
})

const receivePayments = (payments) => ({
    type: PaymentsActions.RECEIVE_PAYMENTS,
    payments
})

const fetchPayments = () => (dispatch) => {
    dispatch(requestPayments())
    return getPayments().then(payments => dispatch(receivePayments(payments)))
}

const shouldFetchPayments = (state) => {
    const payments = state.getIn(['admin', 'payments'])
    return !payments || !payments.get('isFetching')
}

const fetchPaymentsIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchPayments(getState())) {
        return dispatch(fetchPayments())
    }
}

export {
    PaymentsActions,
    fetchPaymentsIfNeeded,
}
