import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {applyMiddleware, compose, createStore} from 'redux'
import {AppContainer} from 'react-hot-loader'
import * as immutable from 'immutable'
import thunk from 'redux-thunk'
import b2cauth from 'react-azure-adb2c'
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import '../styles/omnipro.scss'
import indexReducer from './reducers/index'
import App from './components/App'

JavascriptTimeAgo.locale(en)

b2cauth.initialize({
    tenant: B2C_TENANT,
    signInPolicy: B2C_SIGNIN_POLICY,
    applicationId: B2C_APPLICATION_ID,
    cacheLocation: 'localStorage',
    scopes: [
        'openid',
        'https://omniprohub.onmicrosoft.com/customerhubapi/user_impersonation',
        'https://omniprohub.onmicrosoft.com/customerhubapi/read',
        'https://omniprohub.onmicrosoft.com/customerhubapi/read'
    ],
    redirectUri: B2C_REDIRECT_URI,
    postLogoutRedirectUri: B2C_REDIRECT_URI,
})

const devToolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({shouldHotReload: false})
const composeEnhancers = devToolsCompose || compose
const middlewares = composeEnhancers(applyMiddleware(thunk))
let store = createStore(indexReducer, immutable.Map(), middlewares)

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <AppContainer>
                <App/>
            </AppContainer>
        </Provider>,
        window.document.getElementById('root')
    )
}

if (module.hot) {
    module.hot.accept('./components/App', () => render())
    module.hot.accept('./reducers/index', () => {
        const nextRootReducer = require('./reducers/index')
        store.replaceReducer(nextRootReducer)
    })
}

render()
