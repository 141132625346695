import * as immutable from 'immutable'

import {AccountActions} from '../actions/account'


export default (state = null, action) => {
    switch (action.type) {
        case AccountActions.SET_ACCOUNT:
            state = action.account
            break
        default:
            break
    }
    return state
}
