import {getSubscriptions} from '../apis/subscriptions'


const SubscriptionsActions = {
    REQUEST_SUBSCRIPTIONS: 'API_SUBSCRIPTIONS_LISTS_REQUEST',
    RECEIVE_SUBSCRIPTIONS: 'API_SUBSCRIPTIONS_LISTS_RECEIVE',
}

const requestSubscriptions = () => ({
    type: SubscriptionsActions.REQUEST_SUBSCRIPTIONS,
})

const receiveSubscriptions = (subscriptions) => ({
    type: SubscriptionsActions.RECEIVE_SUBSCRIPTIONS,
    subscriptions
})

const fetchSubscriptions = (userId) => (dispatch) => {
    dispatch(requestSubscriptions())
    return getSubscriptions(userId).then(subscriptions => dispatch(receiveSubscriptions(subscriptions)))
}

const shouldFetchSubscriptions = (state) => {
    const subscriptions = state.get('subscriptions')
    return !subscriptions || !subscriptions.get('isFetching')
}

const fetchSubscriptionsIfNeeded = () => (dispatch, getState) => {
    if (shouldFetchSubscriptions(getState())) {
        return dispatch(fetchSubscriptions(getState().getIn(['account', 'id'])))
    }
}

export {
    SubscriptionsActions,
    fetchSubscriptionsIfNeeded,
}
