import React from 'react'
import {Button, Modal, ModalBody, ModalFooter} from 'mdbreact'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'

import {deleteApplicationApi} from '../../../apis/admin/applications'


class Delete extends React.Component {
    state = {
        loading: false
    }

    handleChange = () => {
        this.setState(
            {loading: true},
            () => {
                const {deleteApplication, applicationId} = this.props
                deleteApplicationApi(applicationId)
                    .then((response) => {
                        deleteApplication(response)
                    })
            }
        )
    }

    render() {
        const {onClose} = this.props
        const {loading} = this.state

        return (
            <Modal isOpen toggle={onClose}>
                <ModalBody>
                    <h3 className="pt-3 pr-2">
                        Delete Application
                    </h3>
                    <div className="row">
                        <div className="col-12">
                            <span>Are you sure to delete this application?</span>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" outline onClick={this.handleChange} disabled={loading}>
                        <div className={classNames({'spinner-border spinner-border-sm': loading})} role="status">
                            <span className={classNames({'sr-only': loading})}>Delete</span>
                        </div>
                    </Button>
                    <Button color="primary" outline onClick={onClose} disabled={loading}>close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}


Delete.propTypes = {
    applicationId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    deleteApplication: PropTypes.func.isRequired
}

export default Delete
