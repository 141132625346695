import * as PropTypes from 'prop-types'
import React from 'react'
import {Link} from 'react-router-dom'
import {
    MDBCollapse,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink
} from 'mdbreact'

import routes, {buildUrl} from '../../utils/routes'


class AdminNavBar extends React.Component {
    render() {
        const {show, isOpen, userName, onLogOutClick} = this.props

        const pathname = window.location.pathname

        if (!show) {
            return null
        }

        return (
            <MDBCollapse isOpen={isOpen} navbar>
                <MDBNavbarNav left>
                    <MDBNavItem active={pathname === buildUrl(routes.adminApplications)}>
                        <MDBNavLink to={buildUrl(routes.adminApplications)}>Applications</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem active={pathname === buildUrl(routes.adminUsers)}>
                        <MDBNavLink to={buildUrl(routes.adminUsers)}>Users</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem active={pathname === buildUrl(routes.adminNotificationTemplates)}>
                        <MDBNavLink to={buildUrl(routes.adminNotificationTemplates)}>Notification Templates</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                                Reports
                            </MDBDropdownToggle>
                            <MDBDropdownMenu right>
                                <MDBDropdownItem className="p-0 text-center">
                                    <Link to={buildUrl(routes.adminReportRevenues)}
                                          className="nav-link rounded black-ic">
                                        Revenues
                                    </Link>
                                </MDBDropdownItem>
                                <MDBDropdownItem className="p-0 text-center">
                                    <Link to={buildUrl(routes.adminReportSubscriptions)}
                                          className="nav-link rounded black-ic">
                                        Subscriptions
                                    </Link>
                                </MDBDropdownItem>
                                <MDBDropdownItem className="p-0 text-center">
                                    <Link to={buildUrl(routes.adminReportFailedPayments)}
                                          className="nav-link rounded black-ic">
                                        Failed Payments
                                    </Link>
                                </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </MDBNavItem>
                </MDBNavbarNav>
                <MDBNavbarNav right>
                    <MDBNavItem className="my-auto font-weight-bolder">
                        <MDBNavLink to={buildUrl(routes.adminUsers)}>{userName}</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem className="my-auto pointer" onClick={onLogOutClick}>
                        <span className="fa-stack light-grey-ic">
                            <i className="fa fa-circle fa-stack-2x"/>
                            <i className="fas fa-sign-out-alt fa-stack-1x fa-inverse"/>
                        </span>
                    </MDBNavItem>
                </MDBNavbarNav>
            </MDBCollapse>
        )
    }
}


AdminNavBar.propTypes = {
    userName: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onLogOutClick: PropTypes.func.isRequired,
}

export default AdminNavBar
