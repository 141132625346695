import React from 'react'
import classNames from 'classnames'

import {resetPassword} from '../../apis/accounts'

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            loading: false,
            email: null,
            passwordReset: false
        }
    }

    onResetPassword = (e) => {
        e.preventDefault()
        this.setState({error: null, loading: true})
        const {email} = this.state
        resetPassword(email).then((response) => {
            if(response.get('success')) {
                this.setState({
                    passwordReset: true
                })
            }
            else {
                this.setState({
                    error: 'Error: ' + response.get('reason'),
                    loading: false
                })
            }
        })
    }

    render() {
        const {loading, passwordReset, email, error} = this.state

        if(passwordReset) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto my-3">
                            <div className="card">
                                <div className="card-body">
                                    <form className="form-signin my-3">
                                        <div>
                                            <img src="/assets/img/logo_colour.svg" height="30"/>
                                        </div>
                                        <span className="mt-2 d-block">Custmomer Portal</span>
                                        <label className="mt-2 d-block">An email has been sent to {email} with the instructions to reset your password</label>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mx-auto my-3">
                        <div className="card">
                            <div className="card-body">
                                <form className="form-signin my-3">
                                    <div>
                                        <img src="/assets/img/logo_colour.svg" height="30"/>
                                    </div>
                                    <span className="mt-2 d-block">Custmomer Portal</span>
                                    <label className="h3 mb-3 font-weight-light small">Enter your email</label>
                                    <label className="mt-2 d-block">{error}</label>
                                    <label htmlFor="email" className="sr-only">Email address</label>
                                    <input 
                                        type="email" 
                                        id="email" 
                                        className="form-control mb-3" 
                                        placeholder="Email address" 
                                        required 
                                        autoFocus
                                        onChange={(event) => this.setState({email: event.currentTarget.value})}/>
                                    <button className="btn btn-primary btn-block" onClick={this.onResetPassword} disabled={loading}>
                                        <div className={classNames({'spinner-border spinner-border-sm': loading})} role="status">
                                            <span className={classNames({'sr-only': loading})}>Reset Password</span>
                                        </div>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword
