import React from 'react'
import {Button, MDBInput, Modal, ModalBody, ModalFooter} from 'mdbreact'
import * as PropTypes from 'prop-types'
import * as immutable from 'immutable'
import {connect} from 'react-redux'
import classNames from 'classnames'

import ApplicationUserToggle from './Toggle'
import {fetchUserAppsIfNeeded} from '../../../actions/admin/userApplications'
import {updateUser} from '../../../actions/admin/users'
import {updateUserApi} from '../../../apis/admin/users'


class Edit extends React.Component {
    state = {
        loading: false,
        id: this.props.user.get('id'),
        name: this.props.user.get('name'),
        primaryEmail: this.props.user.get('primaryEmail'),
        primaryEmailNotifications: this.props.user.get('primaryEmailNotifications'),
        secondaryEmail: this.props.user.get('secondaryEmail'),
        secondaryEmailNotifications: this.props.user.get('secondaryEmailNotifications'),
        company: this.props.user.get('company'),
        address: this.props.user.get('address'),
        town: this.props.user.get('town'),
        county: this.props.user.get('county'),
        postalCode: this.props.user.get('postalCode'),
        country: this.props.user.get('country'),
        phone: this.props.user.get('phone'),
        isAdmin: this.props.user.get('isAdmin'),
    }

    componentDidMount = () => {
        const {dispatch, user} = this.props
        dispatch(fetchUserAppsIfNeeded(user.get('id')))
    }

    handleChange = () => {
        const {dispatch} = this.props
        this.setState(
            {loading: true},
            () => {
                updateUserApi(this.state)
                    .then((response) => {
                        dispatch(updateUser(response))
                        this.setState({loading: false})
                    })
            }
        )
    }

    renderApplicationToggles = () => {
        const {isFetchingUserApps, userApps, applications, user} = this.props

        if (isFetchingUserApps) {
            return null
        }

        if (applications.size === 0) {
            return (
                <div className="alert alert-primary mt-3">
                    <p className="mb-0">
                        There are no applications.
                    </p>
                </div>
            )
        }

        return applications.map((application, index) => {
            const userApp = userApps
                .find(userApp => userApp.get('applicationId') === application.get('id')) || immutable.Map({enabled: false})
            return (
                <ApplicationUserToggle
                    key={index}
                    checked={userApp.get('enabled')}
                    userId={user.get('id')}
                    applicationId={application.get('id')}
                    applicationName={application.get('name')}/>
            )
        })
    }

    render() {
        const {onClose} = this.props
        const {
            loading, name, primaryEmail, primaryEmailNotifications, secondaryEmail, secondaryEmailNotifications,
            company, address, town, county, postalCode, country, phone, isAdmin
        } = this.state

        return (
            <Modal isOpen toggle={onClose}>
                <ModalBody>
                    <h3 className="pt-3 pr-2 border-bottom">
                        {`User Details for ${name}`}
                    </h3>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                value={name}
                                label='Name'
                                onChange={(e) => {
                                    this.setState({name: e.target.value})
                                }}
                                onBlur={this.handleChange}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                checked={isAdmin}
                                labelClass='w-100'
                                label="Is Admin?"
                                type="checkbox"
                                id="isAdmin"
                                onChange={(e) => {
                                    this.setState({isAdmin: e.target.checked}, this.handleChange)
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                value={primaryEmail}
                                type='email'
                                label='Primary Email'
                                onChange={(e) => {
                                    this.setState({primaryEmail: e.target.value})
                                }}
                                onBlur={this.handleChange}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                checked={primaryEmailNotifications}
                                labelClass='w-100'
                                label="Receive notifications"
                                type="checkbox"
                                id="userPrimaryEmailForNotifications"
                                onChange={(e) => {
                                    this.setState({
                                        primaryEmailNotifications: e.target.checked,
                                        secondaryEmailNotifications: !e.target.checked
                                    }, this.handleChange)
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                value={secondaryEmail == null ? '' : secondaryEmail}
                                type='email'
                                label='Secondary Email'
                                onChange={(e) => {
                                    this.setState({secondaryEmail: e.target.value})
                                }}
                                onBlur={this.handleChange}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                checked={secondaryEmailNotifications}
                                labelClass='w-100'
                                label="Receive notifications"
                                type="checkbox"
                                id="userSecondaryEmailForNotifications"
                                onChange={(e) => {
                                    this.setState({
                                        secondaryEmailNotifications: e.target.checked,
                                        primaryEmailNotifications: !e.target.checked
                                    }, this.handleChange)
                                }}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                value={company}
                                label='Company'
                                onChange={(e) => {
                                    this.setState({company: e.target.value})
                                }}
                                onBlur={this.handleChange}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                value={address}
                                label='Address'
                                onChange={(e) => {
                                    this.setState({address: e.target.value})
                                }}
                                onBlur={this.handleChange}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                value={town}
                                label='Town'
                                onChange={(e) => {
                                    this.setState({town: e.target.value})
                                }}
                                onBlur={this.handleChange}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                value={county}
                                label='County'
                                onChange={(e) => {
                                    this.setState({county: e.target.value})
                                }}
                                onBlur={this.handleChange}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <MDBInput
                                value={postalCode}
                                label='Postal Code'
                                onChange={(e) => {
                                    this.setState({postalCode: e.target.value})
                                }}
                                onBlur={this.handleChange}/>
                        </div>
                        <div className="col-6">
                            <MDBInput
                                value={country}
                                label='Country'
                                onChange={(e) => {
                                    this.setState({country: e.target.value})
                                }}
                                onBlur={this.handleChange}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <MDBInput
                                value={phone}
                                label='Phone'
                                onChange={(e) => {
                                    this.setState({phone: e.target.value})
                                }}
                                onBlur={this.handleChange}/>
                        </div>
                    </div>

                    <h3 className="pt-3 pr-2 border-bottom">Applications</h3>
                    {this.renderApplicationToggles()}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={onClose} disabled={loading}>
                        <div className={classNames({'spinner-border spinner-border-sm': loading})} role="status">
                            <span className={classNames({'sr-only': loading})}>Close</span>
                        </div>
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}


Edit.propTypes = {
    user: PropTypes.instanceOf(immutable.Map).isRequired,
    onClose: PropTypes.func.isRequired,
    userApps: PropTypes.instanceOf(immutable.List).isRequired,
    applications: PropTypes.instanceOf(immutable.List).isRequired,
    isFetchingUserApps: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const userApps = state.getIn(['admin', 'userApps'])
        || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        userApps: userApps.get('list'),
        isFetchingUserApps: userApps.get('isFetching')
    }
}

export default connect(mapStateToProps)(Edit)
