import * as PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import * as immutable from 'immutable'
import moment from 'moment'

import {MDBDataTable} from 'mdbreact'

import {fetchPaymentsIfNeeded} from '../../../../actions/admin/payments'
import ExportCSVIcon from '../../../buttons/ExportCSVIcon'
import ExportPDFIcon from '../../../buttons/ExportPDFIcon'

const columns = [
    {
        label: 'Company',
        field: 'company',
        sort: 'asc',
    },
    {
        label: 'Product Purchased',
        field: 'product_purchased',
        sort: 'asc',
    },
    {
        label: 'Price',
        field: 'price',
        sort: 'asc',
    },
    {
        label: 'Date of Payment',
        field: 'date_of_payment',
        sort: 'asc',
    },
]


class List extends React.Component {
    componentDidMount() {
        const {dispatch} = this.props
        dispatch(fetchPaymentsIfNeeded())
    }

    render() {
        const {isFetchingPayments, payments} = this.props

        if (isFetchingPayments) {
            return null
        }

        if (payments.size === 0) {
            return (
                <React.Fragment>
                    <div className="alert alert-primary mt-3">
                        <h4 className="alert-heading">Revenues</h4>
                        <hr/>
                        <p className="mb-0">
                            There are no records.
                        </p>
                    </div>
                </React.Fragment>
            )
        }

        const dataToExport = Array.from(payments.map((payment) => {
            return {
                company: payment.getIn(['user', 'company']),
                product_purchased: payment.get('subscriptions')
                    .map((subscription) => subscription.get('title') + ' ' + subscription.get('description'))
                    .toArray()
                    .join(','),
                price: payment.get('price'),
                date_of_payment: moment.utc(payment.get('date')).format('DD/MM/YYYY HH:mm'),
            }
        }))

        const dataToShow = {
            columns,
            rows: Array.from(payments.map((payment) => {
                return {
                    company: payment.getIn(['user', 'company']),
                    product_purchased: <ul>{
                        payment.get('subscriptions').map((subscription, index) =>
                            <li key={index}>{subscription.get('title') + ' ' + subscription.get('description')}</li>
                        )
                    }</ul>,
                    price: payment.get('price'),
                    date_of_payment: moment.utc(payment.get('date')).format('DD/MM/YYYY HH:mm'),
                }
            }))
        }

        return (
            <div>
                <h4 className="my-3">
                    Revenues
                    <ExportCSVIcon
                        data={dataToExport}
                        filename='Revenues Report.csv'
                        title='Export to CSV'/>
                    <ExportPDFIcon
                        filename='Revenues Report.pdf'
                        title='Export to PDF'/>
                </h4>
                <MDBDataTable
                    striped
                    bordered
                    hover={true}
                    searching={true}
                    data={dataToShow}/>
            </div>
        )
    }
}


List.propTypes = {
    payments: PropTypes.instanceOf(immutable.List).isRequired,
    isFetchingPayments: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const payments = state.getIn(['admin', 'payments'])
        || immutable.Map({isFetching: true, list: immutable.List()})
    return {
        payments: payments.get('list')
            .sort((payA, payB) => {
                const payAM = moment.utc(payA.get('created'))
                const payBM = moment.utc(payB.get('created'))
                if (payAM < payBM) {
                    return -1
                }
                if (payAM > payBM) {
                    return 1
                }
                if (payAM === payBM) {
                    return 0
                }
            }),
        isFetchingPayments: payments.get('isFetching'),
    }
}

export default connect(mapStateToProps)(List)
