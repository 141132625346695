import * as immutable from 'immutable'

import {NotificationTemplatesActions} from '../../actions/admin/notificationTemplates'


export default (state = null, action) => {
    switch (action.type) {
        case NotificationTemplatesActions.REQUEST_NOTIFICATION_TEMPLATES:
            state = immutable.Map({
                isFetching: true,
                list: immutable.List()
            })
            break
        case NotificationTemplatesActions.RECEIVE_NOTIFICATION_TEMPLATES:
            state = immutable.Map({
                isFetching: false,
                list: action.notificationTemplates
            })
            break
        case NotificationTemplatesActions.UPDATE_DELIVERY_NOTIFICATION_TEMPLATES:
            state = immutable.Map({
                isFetching: state.get('isFetching'),
                list: state.get('list').map((notificationTemplate) => {
                    if (notificationTemplate.get('id') === action.id) {
                        return notificationTemplate
                            .set('inAppDelivery', action.inAppDelivery)
                            .set('emailDelivery', action.emailDelivery)
                    }
                    return notificationTemplate
                })
            })
            break
        default:
            break
    }
    return state
}
