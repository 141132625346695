import React from 'react'
import {Button, Modal, ModalBody, ModalFooter} from 'mdbreact'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import b2cauth from 'react-azure-adb2c'


class LogOut extends React.Component {
    state = {
        loading: false
    }

    handleChange = () => {
        this.setState(
            {loading: true},
            () => {
                b2cauth.signOut()
            }
        )
    }

    render() {
        const {onClose} = this.props
        const {loading} = this.state

        return (
            <Modal isOpen toggle={onClose}>
                <ModalBody>
                    <div className="row">
                        <div className="col-12">
                            <h3 className="pt-3 pr-2">
                                Do you want to log out?
                            </h3>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" outline onClick={this.handleChange} disabled={loading}>
                        <div className={classNames({'spinner-border spinner-border-sm': loading})} role="status">
                            <span className={classNames({'sr-only': loading})}>Log Out</span>
                        </div>
                    </Button>
                    <Button color="primary" outline onClick={onClose} disabled={loading}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}


LogOut.propTypes = {
    onClose: PropTypes.func.isRequired,
}

export default LogOut
