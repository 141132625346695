import * as immutable from 'immutable'

import {ApplicationsActions} from '../actions/applications'


export default (state = null, action) => {
    switch (action.type) {
        case ApplicationsActions.REQUEST_APPLICATIONS:
            state = immutable.Map({
                isFetching: true,
                list: immutable.List()})
            break
        case ApplicationsActions.RECEIVE_APPLICATIONS:
            state = immutable.Map({
                isFetching: false,
                list: action.applications
            })
            break
        default:
            break
    }
    return state
}
